/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField";
import ImagePicker from "components/AutoComplete/ImagePicker";
import DetailList from "components/DetailList";
import DynamicSwitch from "components/DynamicSwitch";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import ParentPicker from "components/AsyncSelect/ParentPicker";
import Danger from "components/Danger";

const Information = ({
  formData,
  editPage,
  detailPage,
  handleParentChange,
  selectedParent,
  handleRadioGroupChange,
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { destinationName, top, type, parent, highlight } = formField;
  const {
    top: topV,
    destinationName: destinationNameV,
    type: typeV,
    highlight: highlightV,
  } = values;
  const switches = [
    { id: 1, name: top?.name, label: top?.label, value: topV },
    { id: 4, name: highlight?.name, label: highlight?.label, value: highlightV },
  ];
  const typeOptions = [
    { value: "COUNTRY", label: "COUNTRY" },
    { value: "REGION", label: "REGION" },
    { value: "CITY", label: "CITY" },
  ];

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Destination info</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            destinationName: values?.destinationName,
            type: values?.destinationType,
            parent: values?.destinationType !== "COUNTRY" && values?.parentId?.destinationName,
            highlight: values?.highlight,
            top: values?.top,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!editPage ? (
              <Grid item xs={12}>
                <ImagePicker edit imageType="logo" />
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={12}>
              <FormField
                type={destinationName.type}
                label={destinationName.label}
                name={destinationName.name}
                value={destinationNameV}
                placeholder={destinationName.placeholder}
                error={errors?.destinationName && touched?.destinationName}
                success={destinationNameV?.length > 0 && !errors?.destinationName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DynamicRadioGroup
                groupLabel={type.label}
                name={type.name}
                value={values?.destinationType || typeV}
                options={typeOptions}
                setFieldValue={setFieldValue}
                handleChange={handleRadioGroupChange}
              />
            </Grid>
            {values?.destinationType !== "COUNTRY" && (
              <Grid item xs={12}>
                <ParentPicker
                  type={typeV || values?.destinationType}
                  isClearable
                  parent={parent}
                  handleChange={handleParentChange}
                  setFieldValue={setFieldValue}
                  selectedParent={selectedParent || {}}
                />
                {errors?.parent && touched?.parent && <Danger validation={errors?.parent} />}
              </Grid>
            )}

            <Grid container ml={3} mt={2}>
              {switches?.map((item) => (
                <Grid item xs="auto" key={item.id} mr={2}>
                  <DynamicSwitch
                    label={item.label}
                    name={item.name}
                    value={item.value}
                    location={values?.location}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};
Information.defaultProps = {
  editPage: false,
  detailPage: true,
  handleParentChange: () => {},
  selectedParent: {},
  handleRadioGroupChange: () => {},
};
// typechecking props for Information
Information.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  handleParentChange: PropTypes.func,
  handleRadioGroupChange: PropTypes.func,
  selectedParent: PropTypes.instanceOf(Object),
};

export default Information;
