const parseTime = (time) => {
  if (time === "Open 24 hours") {
    return 0; // Consider the start time as 0 minutes (midnight)
  }
  const [hours, minutes] = time.split(":").map(Number);
  return (hours * 60 + minutes) % 1440; // Use modulo to handle wrap-around at midnight
};

const doTimeIntervalsCoincide = (interval1, interval2) => {
  // Handle "Open 24 hours" case
  if (interval1.time === "Open 24 hours" || interval2.time === "Open 24 hours") {
    return false; // Consider "Open 24 hours" as non-overlapping with any specific time interval
  }

  const [start1, end1] = interval1.time.split("-");
  const [start2, end2] = interval2.time.split("-");

  const startTime1 = parseTime(start1);
  const endTime1 = parseTime(end1);
  const startTime2 = parseTime(start2);
  const endTime2 = parseTime(end2);

  // Handle overnight intervals
  const adjustedEndTime1 = endTime1 < startTime1 ? endTime1 + 1440 : endTime1;
  const adjustedEndTime2 = endTime2 < startTime2 ? endTime2 + 1440 : endTime2;

  // Check if intervals are exactly the same (in case of exact duplicates)
  if (startTime1 === startTime2 && adjustedEndTime1 === adjustedEndTime2) {
    return false; // They are the same; no need to treat as a conflict
  }

  // Check if intervals overlap
  return (
    (startTime1 <= adjustedEndTime2 && adjustedEndTime1 >= startTime2) ||
    (startTime2 <= adjustedEndTime1 && adjustedEndTime2 >= startTime1)
  );
};

const areArraysEqual = (arr1, arr2) =>
  arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);

// array to be checked

export const findAndReplaceDuplicates = (openHours, arrToBeChecked) => {
  const duplicates = [];

  const updatedOpenHours = openHours.map((item) => {
    const matchingItem = arrToBeChecked.find((arrItem) => arrItem.date === item.date);

    if (matchingItem) {
      // Check if the times overlap or are the same
      if (doTimeIntervalsCoincide(item, matchingItem)) {
        duplicates.push({ old: item, new: matchingItem });
      }

      // Replace the existing entry
      return matchingItem;
    }
    return item;
  });

  // Add any new dates that were not found in openHours
  const result = updatedOpenHours.concat(
    arrToBeChecked.filter((item) => !openHours.find((openItem) => openItem.date === item.date))
  );

  return areArraysEqual(updatedOpenHours, openHours) ? [] : result;
};

export default findAndReplaceDuplicates;
