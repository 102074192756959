/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import JSZip from "jszip";
import Papa from "papaparse"; // Import PapaParse library
import { useEffect } from "react";

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const ordinal = (number) => {
  const ordinalRules = new Intl.PluralRules("en", {
    type: "ordinal",
  });
  const suffixes = {
    one: "st",
    two: "nd",
    few: "rd",
    other: "th",
  };
  const suffix = suffixes[ordinalRules.select(number)];
  return number + suffix;
};

export const dateFormatWithDash = (oldDate) => {
  // Check if oldDate is a valid date
  const date = new Date(oldDate);
  if (Number.isNaN(date.getTime())) {
    return "";
  }

  // If the date is valid, format it normally
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatDateTime = (oldDateTime) => {
  const dateTime = new Date(oldDateTime);
  const year = dateTime.getFullYear();
  const month = `0${dateTime.getMonth() + 1}`.slice(-2);
  const day = `0${dateTime.getDate()}`.slice(-2);
  const hours = `0${dateTime.getHours()}`.slice(-2);
  const minutes = `0${dateTime.getMinutes()}`.slice(-2);
  const seconds = `0${dateTime.getSeconds()}`.slice(-2);

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDateTime;
};

export const createFormData = (data) => {
  const form = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value || typeof value === "boolean") {
      if (key === "callToAction" || key === "callToAction2" || key === "retail_price") {
        form.append(key, JSON.stringify(value));
      } else if (
        Array.isArray(value) &&
        key !== "subcategories" &&
        key !== "openHours" &&
        key !== "promoCodes"
      ) {
        value.forEach((item) => {
          if (item && item?.label) {
            form.append(key, item.label);
          } else form.append(key, item);
        });
      } else if (Array.isArray(value) && key === "openHours") {
        value.forEach((item) => {
          if (item) {
            form.append(key, JSON.stringify(item));
          }
          return item;
        });
      } else if (Array.isArray(value) && key === "promoCodes") {
        value.forEach((promoCode) => {
          form.append(key, promoCode);
        });
      } else {
        form.append(key, value);
      }
    }
  });
  return form;
};

export const formatString = (str) => {
  // Replace underscores with spaces
  let formattedString = str.replace(/_/g, " ");
  // Insert a space before each capitalized letter
  formattedString = formattedString.replace(/([A-Z])/g, " $1");
  // Convert to lowercase
  formattedString = formattedString.toLowerCase();
  // Capitalize the first letter of each word
  formattedString = formattedString.replace(/\b\w/g, (l) => l);
  // Return the formatted string
  return formattedString;
};

export const areAllValuesEmpty = (data) =>
  !Object.values(data).some(
    (value) =>
      (typeof value === "string" && value.length > 0) ||
      (Array.isArray(value) && value.length > 0) ||
      (typeof value === "boolean" && value === true) ||
      (value !== null && typeof value === "object" && Object.keys(value).length > 0) ||
      (typeof value === "number" && !Number.isNaN(value))
  );
// new data for opening hours
export const chosenToAllDays = (openHours) => {
  const dayData = [];

  const filterDays = (dayName) =>
    openHours.filter((day) => {
      if (day.date === dayName) {
        return day;
      }
      return null;
    });
  const filterArr = (arr, weekDayNb) => {
    arr.forEach((element) => {
      dayData.push({
        open_all_day: element.time === "Open 24 hours" ? "Open 24 hours" : false,
        closed: element.time === "Closed" ? "Closed" : false,
        open:
          element.time !== "Closed" && element.time !== "Open 24 hours"
            ? element.time.split("-")[0]
            : null,
        close:
          element.time !== "Closed" && element.time !== "Open 24 hours"
            ? element.time.split("-")[1]
            : null,
        weekDay: weekDayNb,
      });
    });
  };
  const mondayData = filterDays("Monday");
  const tuesdayData = filterDays("Tuesday");
  const wednesdayData = filterDays("Wednesday");
  const thursdayData = filterDays("Thursday");
  const fridayData = filterDays("Friday");
  const saturdayData = filterDays("Saturday");
  const sundayData = filterDays("Sunday");

  if (mondayData.length > 0) {
    filterArr(mondayData, 1);
  }
  if (tuesdayData.length > 0) {
    filterArr(tuesdayData, 2);
  }
  if (wednesdayData.length > 0) {
    filterArr(wednesdayData, 3);
  }
  if (thursdayData.length > 0) {
    filterArr(thursdayData, 4);
  }
  if (fridayData.length > 0) {
    filterArr(fridayData, 5);
  }
  if (saturdayData.length > 0) {
    filterArr(saturdayData, 6);
  }
  if (sundayData.length > 0) {
    filterArr(sundayData, 7);
  }
  return dayData;
};

export const allDaysToChosen = (openHours) => {
  const tableData = [];
  openHours?.forEach((openHour) => {
    const obj = { date: "", time: "" };
    switch (openHour.weekDay) {
      case 1: {
        obj.date = "Monday";
        break;
      }
      case 2: {
        obj.date = "Tuesday";
        break;
      }
      case 3: {
        obj.date = "Wednesday";
        break;
      }
      case 4: {
        obj.date = "Thursday";
        break;
      }
      case 5: {
        obj.date = "Friday";
        break;
      }
      case 6: {
        obj.date = "Saturday";
        break;
      }
      case 7: {
        obj.date = "Sunday";
        break;
      }
      default: {
        obj.date = " ";
        break;
      }
    }
    if (openHour.open_all_day) {
      obj.time = "Open 24 hours";
    } else if (openHour.closed) {
      obj.time = "Closed";
    } else if (openHour.open && openHour.close) {
      obj.time = `${openHour.open}-${openHour.close}`;
    } else {
      obj.time = null;
    }

    if (obj.time !== null) {
      tableData.push(obj);
    }
  });
  return tableData;
};

const includeFn = (a, b, nb) => {
  if (nb >= a && nb <= b) {
    return true;
  }
  return false;
};

const updateTime = (time) => {
  const newTimePortions = [];
  const timePortions = time.split(":");
  timePortions.forEach((portion, index) => {
    newTimePortions[index] = `0${portion}`.slice(-2);
  });
  return newTimePortions.join(":");
};
// to check if there is duplicates in the times while adding a new one
export const checkDuplicateTimes = (obj1, obj2) => {
  if (
    obj1.time === "Open 24 hours" ||
    obj1.time === "Closed" ||
    obj2.time === "Open 24 hours" ||
    obj2.time === "Closed"
  ) {
    return true;
  }
  const obj1t1 = updateTime(obj1.time.split("-")[0]);
  const obj1t2 = updateTime(obj1.time.split("-")[1]);
  const obj2t1 = updateTime(obj2.time.split("-")[0]);
  const obj2t2 = updateTime(obj2.time.split("-")[1]);
  if (
    includeFn(obj1t1, obj1t2, obj2t1) ||
    includeFn(obj1t1, obj1t2, obj2t2) ||
    includeFn(obj2t1, obj2t2, obj1t1) ||
    includeFn(obj2t1, obj2t2, obj1t2)
  ) {
    return true;
  }

  return false;
};

// filter if there is the same day with closed/open 24 hr and times
export const filterFn = (myArr, testArray, type) => {
  const filteredClosedData = myArr.filter((el) => el.time === type);
  let canTurn = true;
  let i = 0;
  let newArr = myArr;
  do {
    const day = myArr[i];
    const single = filteredClosedData.find((item) => item.date === day.date);
    testArray = newArr.filter((el) => {
      if (el.date !== single?.date) {
        return el;
      }
      if (el.time === type) {
        return el;
      }
      return null;
    });
    newArr = testArray;
    i++;
    canTurn = i < myArr.length;
  } while (canTurn === true);
  return testArray;
};

export const convertArrayToDate = (arr) => {
  const date = new Date(Date.UTC(arr[0], arr[1] - 1, arr[2], 15, 47, 37));
  const options = {
    dateStyle: "full",
  };
  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate;
};

export const convertArrayToString = (arr) => {
  // Check if the input is an array
  if (!Array.isArray(arr)) {
    throw new Error("Input must be an array of objects");
  }

  // Map over the array to extract the titles and join them with a comma
  return arr.map((obj) => obj.label || obj.name).join(", ");
};

// Define a function to reset the form to its initial state
export const resetFormToInitialState = (formRef, initialState) => {
  if (formRef && formRef?.current) {
    // Set the form values back to the initial state
    formRef?.current?.resetForm({
      values: initialState,
    });
  }
};

const downloadZip = (rows, pageTitle) => {
  // const JSZip = require("jszip");
  const zip = new JSZip();

  // Splitting rows into chunks of 2000
  for (let i = 0; i < rows.length; i += 2000) {
    const chunk = rows.slice(i, i + 2000);
    const csv = Papa.unparse(chunk);

    // Adding each chunk as a CSV file in the ZIP
    zip.file(`${pageTitle}_${i / 2000 + 1}.csv`, csv);
  }

  // Generating ZIP file and triggering download
  zip.generateAsync({ type: "blob" }).then((content) => {
    const url = window.URL.createObjectURL(content);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${pageTitle}.zip`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  });
};

export const downloadCSV = (data, pageTitle) => {
  // 1. Parse the data into an array of objects
  const rows = data.split("\n").map((line) => line.split(","));

  if (rows.length <= 2000) {
    const headers = rows[0];
    const csvData = [];
    for (let i = 1; i < rows.length; i++) {
      const row = rows[i];
      const rowData = {};
      for (let j = 0; j < headers.length; j++) {
        rowData[headers[j]] = row[j];
      }
      csvData.push(rowData);
    }
    // 2. Convert the array of objects into a CSV format
    const csv = Papa.unparse(csvData);
    // 3. Create a Blob containing the CSV data
    const blob = new Blob([csv], { type: "text/csv" });

    // 4. Create a download link for the user to download the CSV file
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${pageTitle}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } else {
    downloadZip(rows, pageTitle);
  }
};

// scrollToTop functions on navigations
export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const useHandleError = (error, dispatch, setAlert, clearErrors) => {
  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, dispatch, setAlert, clearErrors]);
};
