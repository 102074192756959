/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Section, Html, Text } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";

import FooterSection from "../FooterSection";
import IfYouNeedSupportSection from "../IfYouNeedSupport";
import LogoSection from "../LogoSection/index";
import DynamicTicketCard from "../DynamicTicketCard";
import { defaultProps, propTypes } from "./Proptypes";
import HeadSection from "../HeadSection";

const BookingConfirmationEmail = ({ user, token, data }) => (
  <Html>
    <HeadSection />
    <Tailwind>
      <Body className="bg-gray-100 font-sans leading-relaxed">
        <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg mb-2">
          <LogoSection />
          <Section className="text-left bg-white p-6 pl-8 text-black">
            <Text className="text-[35px] leading-[26.5px] font-bold mb-2">
              Thank you for your booking!
            </Text>
            <Text className="text-[24px] leading-[26.5px] font-normal mb-2">
              Welcome {user.name},
            </Text>
            <Text className="text-[24px] leading-[26.5px] font-normal mb-0">Good News!</Text>
            <Text className="text-[24px] leading-[26.5px] font-normal mt-0 mb-4">
              Your booking is confirmed
            </Text>
            <Text className="text-[20px] leading-[23px] font-normal mb-1">
              Your voucher(s) can be found attached to this e-mail.
            </Text>
          </Section>
          <DynamicTicketCard
            isMoreInfo
            isProvider
            isReminder
            data={data}
            token={token}
            user={user}
            noDescription
            isDiscount
          />
          <Text className="text-[14px] leading-[16px] font-normal pl-8 mb-4">
            {data?.cancellationPolicy?.description || ""}
          </Text>
          <hr className="border-t-0  border-[#394A43]" />
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);

BookingConfirmationEmail.defaultProps = defaultProps;

BookingConfirmationEmail.propTypes = propTypes;

export default BookingConfirmationEmail;
