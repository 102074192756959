/* eslint-disable camelcase */

import checkout from "./form";

const {
  formField: {
    // tag page
    title,
    parents,
    top,
    type,
  },
} = checkout;

const initialValues = {
  // information page
  [title.name]: "",
  [parents.name]: [],
  [top.name]: false,
  [type.name]: "RECOMMENDATIONS",
};

export default initialValues;
