import React from "react";
import PropTypes from "prop-types";
import { Body, Container, Html, Img, Section, Text, Row, Column } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import FooterSection from "../FooterSection/index";
import IfYouNeedSupportSection from "../IfYouNeedSupport";
import LogoSection from "../LogoSection";
import HeadSection from "../HeadSection";

const WelcomeEmail = ({ user, token }) => (
  <Html>
    <HeadSection />
    <Tailwind>
      <Body className="bg-gray-100  leading-relaxed">
        <Container className="bg-white my-0 mx-auto p-0 max-w-[600px] border border-[#eaeaea]">
          <LogoSection />
          <Section className="justify-center pt-5 pb-5 text-center px-8">
            <Img
              src="https://email-assets.lokalee.app/email/emailImages/Image.png"
              alt="Welcome"
              className="max-w-full h-auto"
            />
          </Section>
          <Section className="px-5 text-center">
            {/* {line height:leading ,font weight: font-bold(700)/normal(600),text-size:text-xl text-[24px]   } */}
            <Text className="text-[24px] leading-[34px] font-normal mb-2 ">
              Greetings {user.name},
            </Text>
            <Text className="text-[28px] leading-[34px] font-bold mb-2">Welcome to Lokalee!</Text>
          </Section>
          <Section className="bg-[#F2F2F2] px-5 text-center w-500px h-46px border-b-4 border-[#394A43]">
            <Text className="text-[20px] leading-[23px] font-bold">
              We are happy to have you onboard!
            </Text>
            <Text className="text-[20px] leading-[23px] font-bold">
              Please use the following code to validate your email:
            </Text>
            <Text className="text-[25px] leading-[26.5px] font-bold mb-5">{token}</Text>
            <Text className="text-[20px] leading-[23px] font-bold mb-5">
              Thanks,
              <Row>
                <Column className="text-[20px] leading-[23px] font-bold mb-5">Lokalee Team</Column>
              </Row>
            </Text>
          </Section>
          <hr className="border-t-0  border-[#394A43]" />
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);
WelcomeEmail.defaultProps = {
  user: {
    name: "Lokalee",
  },
  token: "961",
};

WelcomeEmail.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
  }),
  token: PropTypes.string,
};

export default WelcomeEmail;
