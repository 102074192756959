/* eslint-disable no-underscore-dangle */
import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PropTypes from "prop-types";

const ItemsTable = ({ items }) => (
  <Table
    style={{
      marginBottom: "25px",
      borderBottom: "hidden",
    }}
  >
    {items.map((value) =>
      value.tickets.map((ticket, i) => (
        <React.Fragment key={i?.length}>
          <TableHead>
            <TableRow>
              <TableCell component="th" style={{ color: "dimgray" }}>
                {value?.ref?.title}
              </TableCell>
              <TableCell component="th" style={{ color: "dimgray" }}>
                Quantity
              </TableCell>
              {/* <TableCell component="th" style={{ color: "dimgray" }}>
                ID
              </TableCell> */}
              <TableCell component="th" style={{ color: "dimgray" }}>
                Unit Price
              </TableCell>
              <TableCell component="th" style={{ color: "dimgray" }}>
                Total Price
              </TableCell>
              <TableCell component="th" style={{ color: "dimgray" }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={value}>
              <TableCell>{ticket.ticketName}</TableCell>
              <TableCell>{ticket?.ticketQuantity}</TableCell>
              {/* <TableCell>{page === "concierge" ? ticket._id : ticket.ticketId}</TableCell> */}
              <TableCell>{ticket?.ticketPrice}</TableCell>

              <TableCell>{ticket?.totalPrice || 0}</TableCell>

              <TableCell>{value?.status}</TableCell>
            </TableRow>
          </TableBody>
        </React.Fragment>
      ))
    )}
  </Table>
);

ItemsTable.defaultProps = {
  items: [],
  // page: "",
};

ItemsTable.propTypes = {
  items: PropTypes.instanceOf(Array),
  // page: PropTypes.string,
};

export default ItemsTable;
