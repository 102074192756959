const form = {
  formId: "new-venue-form",
  formField: {
    // venue page
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },
    fomoMessage: {
      name: "fomoMessage",
      label: "Fomo Message ",
      type: "text",
    },
    thumbnailHiResURL: {
      name: "thumbnailHiResURL",
      label: "Logo *",
      type: "text",
      errorMsg: "Logo is required!",
    },
    // address page
    primaryDestinationId: {
      name: "primaryDestinationId",
      label: "Primary Destination *",
      type: "text",
      errorMsg: "Primary Destination is required!",
    },
    address: {
      name: "address",
      label: "Address *",
      type: "text",
      errorMsg: "Address is required!",
      invalidMsg: "Address should have at least 1 letter!",
    },
    country: {
      name: "country",
      label: "Country",
      type: "text",
    },
    city: {
      name: "city",
      label: "City",
      type: "text",
    },
    region: {
      name: "region",
      label: "Region",
      type: "text",
    },
    lat: {
      name: "lat",
      label: "Latitude",
      type: "text",
    },
    lng: {
      name: "lng",
      label: "Longitude",
      type: "text",
    },

    top: {
      name: "top",
      label: "Top",
      type: "boolean",
    },
  },
};

export default form;
