/**
=========================================================
* LCM Dashboard V2
=========================================================

* Product Page: https://www.lcm.lokalee.app
* Copyright 2023 Lokalee (https://www.lokalee.app)

 =========================================================
*/

const form = {
  formId: "new-ticket-form",
  formField: {
    // ticket page
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },
    type: {
      name: "type",
      label: "Type *",
      type: "text",
      errorMsg: "Type is required!",
    },
    tag: {
      name: "tag",
      label: "Tag *",
      type: "text",
      errorMsg: "Tag is required!",
    },
    themes: {
      name: "themes",
      label: "Themes",
      type: "text",
      errorMsg: "Theme is required!",
      invalidMsg: "Should have at least 1 theme!",
    },
    overview: {
      name: "overview",
      label: "Overview *",
      type: "text",
      errorMsg: "Overview is required!",
    },

    primaryDestination: {
      name: "primaryDestination",
      label: "Primary Destination *",
      type: "text",
      errorMsg: "Primary Destination is required!",
    },
    destinations: {
      name: "destinations",
      label: "Destinations *",
      type: "text",
      errorMsg: "Destination is required!",
      invalidMsg: "Should have at least 1 destination!",
    },

    curator: {
      name: "curator",
      label: "Curator *",
      type: "object",
      errorMsg: "Curator is required!",
    },

    curatorName: {
      name: "curatorName",
      label: "Curator Name",
      type: "text",
    },

    curatorContact: {
      name: "curatorContact",
      label: "Curator Contact",
      type: "text",
    },

    curatorEmail: {
      name: "curatorEmail",
      label: "Curator Email",
      type: "text",
    },

    curatorOrProv: {
      name: "curatorOrProv",
      label: "Curator Or Provider",
      type: "text",
    },

    provider: {
      name: "provider",
      label: "Provider",
      type: "object",
    },
    providerName: {
      name: "providerName",
      label: "Provider Name",
      type: "text",
    },
    providerContact: {
      name: "provider",
      label: "Provider Contact",
      type: "text",
    },
    providerEmail: {
      name: "providerEmail",
      label: "Provider Email",
      type: "text",
    },

    // content

    venues: {
      name: "venues",
      label: "Venue",
      type: "array",
      errorMsg: "Venues is required!",
      invalidMsg: "Should have at least 1 venue!",
    },
    isOneVenue: {
      name: "isOneVenue",
      label: "isOneVenue",
      type: "text",
      // errorMsg: "Theme is required!",
      // invalidMsg: "Should have at least 1 theme!",
    },

    durationRadio: {
      name: "durationRadio",
      label: "Duration *",
      type: "text",
      // errorMsg: "Theme is required!",
      // invalidMsg: "Should have at least 1 theme!",
    },

    durationRadio2: {
      name: "durationRadio2",
      label: "Ticket Duration *",
      type: "text",
      // errorMsg: "Theme is required!",
      // invalidMsg: "Should have at least 1 theme!",
    },

    skipTheLine: {
      name: "skipTheLine",
      label: "Skip the line",
      type: "boolean",
    },

    containStops: {
      name: "containStops",
      label: "Stops",
      type: "boolean",
    },

    day: {
      name: "day",
      label: "Day",
      type: "number",
    },
    hour: {
      name: "hour",
      label: "Hour",
      type: "number",
    },
    min: {
      name: "min",
      label: "Minute",
      type: "number",
    },
    from: {
      name: "from",
      label: "From",
      type: "number",
    },
    to: {
      name: "to",
      label: "To",
      type: "number",
    },

    day2: {
      name: "day2",
      label: "Day",
      type: "number",
    },
    hour2: {
      name: "hour2",
      label: "Hour",
      type: "number",
    },
    min2: {
      name: "min2",
      label: "Minute",
      type: "number",
    },
    from2: {
      name: "from2",
      label: "From",
      type: "number",
    },
    to2: {
      name: "to2",
      label: "To",
      type: "number",
    },
    timeType2: {
      name: "timeType2",
      label: "Time Type 2",
      type: "text",
    },

    itineraryItems: {
      name: "itineraryItems",
      label: "Itinerary items",
      type: "array",
    },
    category: {
      // category to be for inclusion
      name: "category",
      label: "Category *",
      type: "text",
      errorMsg: "Category is required!",
      invalidMsg: "Category should have at least 1 letter!",
    },

    titlePkr: {
      // titlePkr to be for inclusion
      name: "titlePkr",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },

    titlePkrExc: {
      // titlePkr to be for inclusion
      name: "titlePkrExc",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },
    typeText: {
      // typeText to be for inclusion
      name: "typeText",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },

    typeTextExc: {
      // typeText to be for exclusion
      name: "typeTextExc",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },

    badge: {
      name: "badge",
      label: "Badge",
      type: "text",
      errorMsg: "Badge is required!",
    },
    languageGuides: {
      name: "languageGuides",
      label: "Language Guides",
      type: "array",
      errorMsg: "Language is required!",
      invalidMsg: "Badge should have at least 1 letter!",
    },

    pin: {
      name: "pin",
      label: "Stops",
      type: "object",
      errorMsg: "Pin is required!",
    },

    pinsStart: {
      name: "pinsStart",
      label: "Start point:",
      type: "array",
      errorMsg: "Start point is required!",
    },

    pinsEnd: {
      name: "pinsEnd",
      label: "End point:",
      type: "array",
      errorMsg: "End point is required!",
    },

    pickupOptionType: {
      name: "pickupOptionType",
      label: "Pickup Option type",
      type: "text",
      errorMsg: "Pickup Option type is required!",
    },

    pickupPoint: {
      name: "pickupPoint",
      label: "Pickup Point",
      type: "text",
      errorMsg: "Pickup point  is required!",
    },
    pickupType: {
      name: "pickupType",
      label: "Pickup type",
      type: "text",
      errorMsg: "Pickup type  is required!",
    },
    travelerPickup: {
      name: "travelerPickup",
      label: "Traveler Pickup",
      type: "array",
      // errorMsg: "Pickup type  is required!",
    },

    allowCustomTravelerPickup: {
      name: "allowCustomTravelerPickup",
      label: "allowCustomTravelerPickup",
      type: "boolean",
    },
    additionalPickupInfo: {
      name: "additionalPickupInfo",
      label: "Additional pickup information",
      type: "text",
      errorMsg: "Additional info is required!",
    },

    dateFrom: {
      name: "dateFrom",
      label: "Date From",
      type: "text",
    },
    dateTo: {
      name: "dateTo",
      label: "Date To",
      type: "text",
    },

    minutesBeforeDepartureTimeForPickup: {
      name: "minutesBeforeDepartureTimeForPickup",
      label: "Pickup before:",
      type: "number",
    },
    dayRangeMax: {
      name: "dayRangeMax",
      label: "Days before activity",
      type: "number",
    },
    percentageRefundable: {
      name: "percentageRefundable",
      label: "Percentage Refundable",
      type: "number",
    },

    // additional info

    additionalInfo: {
      name: "additionalInfo",
      label: "Additional Info",
      type: "array",
      errorMsg: "Additional info is required!",
    },

    physicalDifficultyLevel: {
      name: "physicalDifficultyLevel",
      label: "Physical Difficulty Level",
      type: "text",
    },
    healthRestrictions: {
      name: "healthRestrictions",
      label: "Health Restrictions",
      type: "text",
    },
    physicalRestrictions: {
      name: "physicalRestrictions",
      label: "Physical Restrictions",
      type: "text",
    },

    // badges
    top: {
      name: "top",
      label: "Top",
      type: "boolean",
    },

    highlight: {
      name: "highlight",
      label: "Highlight",
      type: "boolean",
    },

    // media
    video: {
      name: "video",
      label: "Video URL",
      type: "text",
      invalidMsg: "Please enter a valid Youtube url! (http://www.youtube.com)!",
    },

    // schedules and pricing
    days: {
      name: "days",
      label: "Days *",
      type: "array",
      errorMsg: "Please select at least 1 day!",
    },

    times: {
      name: "times",
      label: "Times *",
      type: "array",
      errorMsg: "Please select at least 1 time!",
    },

    startDate: {
      name: "startDate",
      label: "Start date: *",
      type: "text",
      errorMsg: "Start Date is required!",
    },

    endDate: {
      name: "endDate",
      label: "End date:",
      type: "text",
      errorMsg: "End Date is required!",
    },

    defaultCurrencyCode: {
      name: "defaultCurrencyCode",
      label: "Currency *",
      type: "object",
      errorMsg: "Currency is required!",
    },

    // booking and tickets

    bookingCutoffType: {
      name: "bookingCutoffType",
      label: "Cut -off time by:",
      type: "text",
    },
    cancellationPolicy: {
      name: "cancellationPolicy",
      label: "Cancellation Policy",
      type: "text",
    },
    cancelIfBadWeather: {
      name: "cancelIfBadWeather",
      label: "Policy",
      type: "boolean",
    },
    ticketType: {
      name: "ticketType",
      label: "Select ticket type:*",
      type: "text",
    },
    daysCutoff: {
      name: "daysCutoff",
      label: "Days",
      type: "number",
    },

    timeCutoff: {
      name: "timeCutoff",
      label: "Time",
      type: "object",
    },

    hoursAct: {
      name: "hoursAct",
      label: "Hours",
      type: "number",
    },
    confirmationType: {
      name: "confirmationType",
      label: "Booking Confirmation: *",
      type: "number",
    },

    ticketsPerBooking: {
      name: "ticketsPerBooking",
      label: "How many tickets created per booking:",
      type: "text",
    },

    // ////////////////////////////////
    // for oldddd

    identifier: {
      name: "identifier",
      label: "Identifier *",
      type: "text",
      errorMsg: "Identifier is required!",
      invalidMsg: "Identifier should have at least 1 letter!",
    },

    description: {
      name: "description",
      label: "Description *",
      type: "text",
    },
    whatToExpect: {
      name: "whatToExpect",
      label: "Know before you go",
      type: "text",
    },
    available_quantity: {
      name: "available_quantity",
      label: "Available Quantity",
      type: "number",
    },
    relevance: {
      name: "relevance",
      label: "Relevance",
      type: "number",
    },

    subcategories: {
      name: "subcategories",
      label: "Subcategories",
      type: "array",
    },
    logo: {
      name: "logo",
      label: "Logo *",
      type: "text",
      errorMsg: "Logo is required!",
    },
    supplierName: {
      name: "supplierName",
      label: "Provider Name *",
      type: "text",
      errorMsg: "Provider name is required!",
    },

    disabled: {
      name: "disabled",
      label: "Disable item",
      type: "boolean",
    },
    // address page
    location: {
      name: "location",
      label: "Location",
      type: "text",
    },
    // address: {
    //   name: "address",
    //   label: "Address *",
    //   type: "text",
    //   errorMsg: "Address is required!",
    //   invalidMsg: "Address should have at least 1 letter!",
    // },
    country: {
      name: "country",
      label: "Country",
      type: "text",
    },
    // city: {
    //   name: "city",
    //   label: "City *",
    //   type: "text",
    //   errorMsg: "City is required!",
    //   invalidMsg: "City should have at least 1 letter!",
    // },
    region: {
      name: "region",
      label: "Region",
      type: "text",
    },
    lat: {
      name: "lat",
      label: "Latitude *",
      type: "text",
      errorMsg: "Lat is required!",
    },
    lng: {
      name: "lng",
      label: "Longitude *",
      type: "text",
      errorMsg: "Lng is required!",
    },
    // additional page

    tags: {
      name: "tags",
      label: "Tags",
      type: "array",
    },

    // price info page
    availability_type: {
      name: "availability_type",
      label: "Availability Type *",
      type: "text",
      // invalidMsg: "Availability Type should have at least 1 letter",
      errorMsg: "Availability Type is required!",
    },
    option: {
      name: "option",
      label: "Option *",
      type: "text",
      invalidMsg: "Option should have at least 1 letter!",
      errorMsg: "Option is required!",
    },
    accompanied: {
      name: "accompanied",
      label: "Do children need to be accompanied by at least one adult or senior?",
      type: "boolean",
    },
    minTravelersPerBooking: {
      name: "minTravelersPerBooking",
      label: "Minimum number of travelers per booking:*",
      type: "number",
      errorMsg: "Minimum number of travelers is required!",
    },
    maxTravelersPerBooking: {
      name: "maxTravelersPerBooking",
      label: "Maximum number of travelers per booking:*",
      type: "number",
      errorMsg: "Maximum number of travelers is required!",
    },
    pricingInfo: {
      name: "pricingInfo",
      label: "Price the ticket by *",
      type: "text",
    },
    scheduleOrOption: {
      name: "scheduleOrOption",
      label: "",
      type: "text",
    },
    optionName: {
      name: "optionName",
      label: "Option Name *",
      type: "text",
      errorMsg: "Option name is required!",
    },
    descriptionOption: {
      name: "descriptionOption",
      label: "Description",
      type: "text",
    },
    startAge: {
      name: "startAge",
      label: "Min Age *",
      type: "number",
    },
    minAge: {
      name: "minAge",
      label: "Min Age",
      type: "number",
      errorMsg: "Min Age is required!",
    },
    maxAge: {
      name: "maxAge",
      label: "Max Age",
      type: "number",
      errorMsg: "Max age is required!",
    },
    endAge: {
      name: "endAge",
      label: "Max Age *",
      type: "number",
    },

    ageBands: {
      name: "ageBands",
      label: "",
      type: "array",
    },
    ageBandsObject: {
      name: "ageBandsObject",
      label: "",
      type: "array",
    },
    pricesObject: {
      name: "pricesObject",
      label: "",
      type: "array",
    },
    availabilities: {
      name: "availabilities",
      label: "Availabilities",
      type: "object",
      invalidMsg: "Availabilities should have at least 1 Item!",
      errorMsg: "Availabilities is required!",
    },
    availabilitiesAdded: {
      name: "availabilitiesAdded",
      label: "Availabilities ",
      type: "array",
      invalidMsg: "Availabilities should have at least 1 Item!",
      errorMsg: "Availabilities is required!",
    },

    retailPrice: {
      name: "retailPrice",
      label: "Retail Price *",
      type: "number",
      invalidMsg: "Retail Price should not be empty!",
      errorMsg: "Retail Price is required!",
    },
    netPrice: {
      name: "netPrice",
      label: "Net Price *",
      type: "number",
      invalidMsg: "Net Price should not be empty!",
      errorMsg: "Net Price is required!",
    },

    tktDate: {
      name: "date",
      label: "Date *",
      type: "text",
      errorMsg: "Date is required!",
    },
    maxQty: {
      name: "maxQty",
      label: "Max Qty *",
      type: "number",
      errorMsg: "MaxQty is required!",
      invalidMsg: "MaxQty must be a postive number and greater than MinQty!",
    },
    minQty: {
      name: "minQty",
      label: "Min Qty *",
      type: "number",
      errorMsg: "MinQty is required!",
      invalidMsg: "MinQty must be a positive number",
    },

    // addditional info page
    // highlight: {
    //   name: "highlight",
    //   label: "Highlight",
    //   type: "boolean",
    // },
    insider: {
      name: "insider",
      label: "Insider Tip",
      type: "boolean",
    },
    sponsored: {
      name: "sponsored",
      label: "Sponsored",
      type: "boolean",
    },
    // cancellationPolicy: {
    //   name: "cancellationPolicy",
    //   label: "Cancellation Policy",
    //   type: "text",
    // },
    currated: {
      name: "currated",
      label: "Currated",
      type: "boolean",
    },

    special: {
      name: "special",
      label: "Special Offer",
      type: "boolean",
    },
    experienceX: {
      name: "experienceX",
      label: "Not to miss",
      type: "boolean",
    },
    exclusive: {
      name: "exclusive",
      label: "Exclusive",
      type: "boolean",
    },

    last_chance: {
      name: "last_chance",
      label: "Last Chance",
      type: "boolean",
    },
    top_seller: {
      name: "top_seller",
      label: "Top Seller",
      type: "boolean",
    },
    likely_to_sell_out: {
      name: "likely_to_sell_out",
      label: "Likely To Sell Out",
      type: "boolean",
    },
    must_see: {
      name: "must_see",
      label: "Must See",
      type: "boolean",
    },
    free_cancellation: {
      name: "free_cancellation",
      label: "Free Cancellation",
      type: "boolean",
    },
    daily: {
      name: "daily",
      label: "Daily",
      type: "boolean",
    },
    // top: {
    //   name: "top",
    //   label: "Top",
    //   type: "boolean",
    // },
    cancellable: {
      name: "cancellable",
      label: "Cancellable",
      type: "boolean",
    },
    openHours: {
      name: "openHours",
      label: "Open Hours",
      type: "array",
    },
    altTextField: {
      name: "altTextField",
      label: "Alt Text Field",
      type: "text",
    },
    metaFields: {
      name: "metaFields",
      label: "Meta Fields",
      type: "text",
    },
    metaTitleField: {
      name: "metaTitleField",
      label: "Meta Title Field",
      type: "text",
    },

    // What to Expect page
    included: {
      name: "included",
      label: "Included",
      type: "array",
    },
    not_included: {
      name: "not_included",
      label: "Not Included",
      type: "array",
    },
    highlights: {
      name: "highlights",
      label: "Highlights",
      type: "array",
    },

    url: {
      name: "url",
      label: "URL",
      type: "text",
      invalidMsg: "Please enter a valid contact url! (http://www.url.com)!",
    },
    languages: {
      name: "languages",
      label: "Languages",
      // type: "array",
      type: "text",
    },

    cutoff_time: {
      name: "cutoff_time",
      label: "Cut-off Time",
      type: "number",
      invalidMsg: "Please enter a positive number!",
    },
    cutoff_days: {
      name: "cutoff_days",
      label: "Cut-off days",
      type: "number",
      invalidMsg: "Please enter a positive number!!",
    },
    about: {
      name: "about",
      label: "What to expect",
      type: "text",
    },
    meeting_point: {
      name: "meeting_point",
      label: "Meeting Point",
      type: "text",
    },
    source: {
      name: "source",
      label: "Source",
      type: "text",
      // invalidMsg: "Availability Type should have at least 1 letter",
      errorMsg: "Source is required!",
    },
    images: {
      name: "images",
      label: "Images",
      type: "text",
      errorMsg: "Images are required!",
    },
    // Trip planner
    style: {
      name: "style",
      label: "Styles *",
      type: "array",
      errorMsg: "Style is required!",
      invalidMsg: "Please add at least one style!",
    },
    interests: {
      name: "interests",
      label: "Interests *",
      type: "array",
      errorMsg: "Interest is required!",
      invalidMsg: "Please add at least one interest!",
    },
    kidsFriendly: {
      name: "kidsFriendly",
      label: "kids Friendly",
      type: "boolean",
    },
    timeSlotPreference: {
      name: "timeSlotPreference",
      label: "Time Slot *",
      type: "text",
    },
    seasonality: {
      name: "seasonality",
      label: "Seasonality *",
      type: "text",
    },
    genderPreference: {
      name: "genderPreference",
      label: "Gender *",
      type: "text",
    },
  },
};

export default form;
