/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
// React
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sortGlobalBox, clearErrors, sortBox, sortLocationBox } from "store/actions/boxActions";
import setAlert from "store/actions/alertActions";
import { deleteMenu } from "store/actions/menuActions";
// Components:
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";
import { getCsv, getPdf } from "store/actions/exportAction";

const GlobalMenusList = ({
  list,
  data,
  loading,
  tabValue,
  selectedDestinations,
  selectedLocations,
}) => {
  const dispatch = useDispatch();
  const tableRef = createRef();
  const user = useSelector((state) => state.users.user);
  const error = useSelector((state) => state.boxes.error);
  const added = useSelector((state) => state.boxes.added);

  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState(""); // State to manage search input

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    setSearch(""); // Reset search input when tab changes
  }, [tabValue]);

  const deleteMenuFn = (oldData) => {
    dispatch(deleteMenu(oldData));
  };

  return (
    <MDBox>
      <MaterialTablePicker
        key={added}
        screen={list.screen}
        title={list.title}
        tableRef={tableRef}
        onSearchChange={(event) => setSearch(event)} // Handle search input change
        searchValue={search} // Pass search value to MaterialTablePicker
        columns={list.columns}
        filteringOptions={false}
        data={tableData}
        isLoading={loading}
        exportMenuField="globalBoxes"
        editableDelete={(oldData) => deleteMenuFn(oldData)}
        deleteRoleAction
        currentUrl="menus"
        addUrl="menus/new-menu"
        editableRole={user.role === "admin"}
        onRowClickURL={`/${user.role}/menus`}
        isOrder
        locationBoxesTable={tabValue === 2}
        boxesTable={tabValue === 1}
        sortFn={tabValue === 1 ? sortBox : tabValue === 2 ? sortLocationBox : sortGlobalBox}
        actionsRole={user.role === "admin"}
        getPdf={(value) => {
          if (value) {
            dispatch(
              getPdf(
                value,
                tabValue === 1
                  ? `stats/pdf?destinations=${selectedDestinations[0]?.value}&type=destinations`
                  : tabValue === 2
                  ? `stats/pdf?locations=${selectedLocations[0]?._id}&type=location`
                  : `stats/pdf?page=1&type=global`,
                "Menus"
              )
            );
          } else {
            console.error("Error: No data for PDF export.");
          }
        }}
        getCsv={() => {
          if (user?.role === "admin") {
            dispatch(
              getCsv(
                tabValue === 1
                  ? `stats/csv?destinations=${selectedDestinations[0]?.value}&type=destinations`
                  : tabValue === 2
                  ? `stats/csv?locations=${selectedLocations[0]?._id}&type=location`
                  : `stats/csv?page=1&type=global`,
                "Menus"
              )
            );
          } else {
            console.error("Error: No data for CSV export.");
          }
        }}
      />
    </MDBox>
  );
};

// Setting default props
GlobalMenusList.defaultProps = {
  data: [],
  list: {},
  selectedDestinations: {},
  selectedLocations: {},
};

// typechecking props for GlobalMenusList
GlobalMenusList.propTypes = {
  list: PropTypes.instanceOf(Object),
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  loading: PropTypes.bool.isRequired,
  tabValue: PropTypes.number.isRequired,
  selectedDestinations: PropTypes.instanceOf(Object),
  selectedLocations: PropTypes.instanceOf(Object),
};

export default GlobalMenusList;
