/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Material UI components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";
import ViewEditActions from "components/DetailList/ViewEditActions";
import { useHandleError } from "components/helper";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import { updateBanner, clearErrors, getOneBanner, setLoading } from "store/actions/bannerActions";
import setAlert from "store/actions/alertActions";
// Schemas
import validations from "../new-banner/schemas/validations";

import form from "../new-banner/schemas/form";
import initialValues from "../new-banner/schemas/initialValues";
import BannerDetail from "./components/BannerDetail";

const EditBanner = () => {
  const { formId, formField } = form;
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   const loading = useSelector((state) => state.banners.loading);
  const banner = useSelector((state) => state.banners.banner);
  //   const banners = useSelector((state) => state.banners.banners);
  const error = useSelector((state) => state.banners.error);
  const added = useSelector((state) => state.banners.added);
  const user = useSelector((state) => state.users.user);
  //   const [tabValue, setTabValue] = useState(0);

  const [imgDetailPage, setImgDetailPage] = useState(true);
  //   const [image, _setImage] = useState(banner?.image);

  const [bannerPage, setBannerPage] = useState(true);
  const [initialState, setInitialState] = useState(initialValues);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const { banner_id } = useParams();

  useEffect(() => {
    if (banner_id) {
      dispatch(getOneBanner(banner_id));
    }
  }, [banner_id, added]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (banner) {
      const manipulated = {};
      manipulated.title = banner?.title ? banner?.title : "";
      manipulated.position = banner?.position ? banner?.position : {};
      manipulated.image = banner?.image ? [banner?.image] : [];

      manipulated._id = banner?._id;

      setSelectedPosition(banner?.position);
      setInitialState(manipulated);
    }
  }, [banner]);

  const handlePositionChange = (value) => {
    if (value && value?.value) {
      setSelectedPosition(value);
    } else {
      setSelectedPosition({});
    }
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const submitForm = async (values) => {
    const {
      title = "",
      position = "",
      // image = "",
    } = values;

    const baseValues = { _id: values?._id };
    let additionalValues = {};

    additionalValues = {
      title,
      position,
      //   image,
    };

    const updatedValues = { ...baseValues, ...additionalValues };
    await sleep(1000);
    dispatch(setLoading());
    // eslint-disable-next-line no-alert
    dispatch(updateBanner(updatedValues, values?.image));
    setBannerPage(true);
    setImgDetailPage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/banners?`;
      navigate(url);
    }
  };

  function getStepContent(type, formData) {
    if (type === "Information") {
      return (
        <BannerDetail
          type={type}
          selectedPosition={selectedPosition}
          handlePositionChange={handlePositionChange}
          formData={formData}
          detailPage={bannerPage}
          setDetailPage={setBannerPage}
          handleEdit={() => {
            formData.resetForm();
            setBannerPage(false);
            setImgDetailPage(true);
          }}
        />
      );
    }
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar hide={user.role === "partner"} />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          {" "}
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={validations[0]}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <DynamicHeader
                      user={banner}
                      noImg
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      <ViewEditActions
                        detailPage={imgDetailPage}
                        stateName="banners"
                        handleEdit={() => {
                          setImgDetailPage(false);
                          setBannerPage(true);
                        }}
                        handleClose={() => {
                          //   _setImage(banner?.image);

                          setImgDetailPage(true);
                        }}
                        handleSave={() => {
                          submitForm(values);

                          setImgDetailPage(true);
                        }}
                      />
                    </DynamicHeader>
                    <>
                      {["Information"].map((item, index) => (
                        <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                          <MDBox p={2}>
                            <Grid container spacing={3} alignItems="center">
                              <Grid item xs={12}>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                  {getStepContent(item, {
                                    values,
                                    touched,
                                    formField,
                                    errors,
                                    setFieldValue,
                                    resetForm,
                                  })}
                                </MDBox>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </Card>
                      ))}
                    </>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditBanner;
