import * as Yup from "yup";
import checkout from "../../new-destination/schemas/form";

const {
  formField: { destinationName, address, timeZone, countryCode },
} = checkout;

const validations = [
  Yup.object().shape({
    // [logo.name]: Yup.string().required(logo.errorMsg).nullable(),
    [destinationName.name]: Yup.string()
      .matches(/([A-Za-z])/, destinationName.invalidMsg)
      .required(destinationName.errorMsg),
  }),
  Yup.object().shape({
    [address.name]: Yup.string()
      .matches(/([A-Za-z\u0600-\u06FF])/, address.invalidMsg)
      .required(address.errorMsg),
    [timeZone.name]: Yup.object().required(timeZone.errorMsg),
    [countryCode.name]: Yup.string().required(countryCode.errorMsg),
  }),
];

export default validations;
