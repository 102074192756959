/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Section, Html, Text } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import LogoSection from "../../LogoSection";
import IfYouNeedSupportSection from "../../IfYouNeedSupport";
import FooterSection from "../../FooterSection";
import DynamicTicketCard from "../../DynamicTicketCard";
import DiscoverAlternativePlacesSection from "../../DynamicDiscoverAlternativePlaces";
import { defaultProps, propTypes } from "../Proptypes";
import HeadSection from "../../HeadSection";

const BookingCancelledEmail = ({ user, token, data, alternativeItems }) => (
  <Html>
    <HeadSection />
    <Tailwind>
      <Body className="bg-gray-100 font-sans leading-relaxed">
        <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
          <LogoSection />
          <Section className="text-start bg-white p-5 px-8 text-black mb-6">
            <Text className="text-[35px] leading-[26.5.px] font-bold mb-2 text-[#222D29]">
              Booking Cancelled!
            </Text>
            <Text className="text-[20px] leading-[23px] font-bold mb-2 text-[#222D29]">
              You have successfully cancelled your booking for:
            </Text>
          </Section>
          <DynamicTicketCard
            isMoreInfo
            isProvider
            isReminder
            data={data}
            user={user}
            token={token}
            noAction
            noDescription
          />

          <DiscoverAlternativePlacesSection alternativeItems={alternativeItems} noViewMore />
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);

BookingCancelledEmail.defaultProps = defaultProps;

BookingCancelledEmail.propTypes = propTypes;
export default BookingCancelledEmail;
