/* eslint-disable no-underscore-dangle */

import PropTypes from "prop-types";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "store/actions/categoryActions";
import Slider from "@mui/material/Slider";
import { Rating } from "@mui/material";
import { setPriceFilter } from "store/actions/filterActions";
import SavedButton from "components/SavedButton";
import { getOneLocation } from "store/actions/locationAction";

const ByPrice = ({ locationId }) => {
  const dispatch = useDispatch();

  const [rateChange, setRateChange] = useState(false);

  const location = useSelector((state) => state.locations.location);

  const [rating, setRating] = useState(3);
  const [value, setValue] = useState(
    location?.filters?.price
      ? [location?.filters?.price?.min, location?.filters?.price?.max]
      : [2, 4]
  );

  useEffect(() => {
    if (locationId) {
      dispatch(setLoading());
      dispatch(getOneLocation(locationId));
    }
  }, [locationId]);

  useEffect(() => {
    if (location?.filters?.rating) {
      setRating(location?.filters?.rating);
    }
  }, [location?.filters?.rating]);
  const handleSave = () => {
    dispatch(setPriceFilter(locationId, value[0], value[1], rating));
  };

  const handleChangeRating = (e, option) => {
    setRating(option);
  };

  const handleChangePrice = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card id="price-ratings" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Filter by Price and Rating</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDTypography variant="body2">
              Choose attractions within the following Price Range only
            </MDTypography>
            <div style={{ display: "flex", gap: 10, marginTop: "4px" }}>
              <Slider
                getAriaLabel={() => "Price range"}
                value={value}
                onChange={handleChangePrice}
                valueLabelDisplay="auto"
                // getAriaValueText={valuetext}
                style={{
                  marginTop: "20px",
                }}
                step={1}
                marks
                min={0}
                max={5}
                sx={{
                  color: "#003005", // This will set the color for the active and default states
                  "& .MuiSlider-track": {
                    backgroundColor: "#003005",
                    border: "none",
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="body2">
              Choose attractions Within the following Rating only
            </MDTypography>
            <Rating
              key={rating}
              name="simple-controlled"
              value={
                // eslint-disable-next-line no-nested-ternary
                !rateChange
                  ? location?.filters?.rating
                    ? location?.filters?.rating
                    : rating
                  : rating
              }
              precision={0.5}
              onChange={(e, val) => {
                setRateChange(true);
                handleChangeRating(e, val);
              }}
              size="large"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} justifyContent="end" display="flex" mt={2}>
            <SavedButton
              handleSave={() => {
                setRateChange(false);
                handleSave();
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

// Setting default props
ByPrice.defaultProps = {
  locationId: "",
};

// typechecking props for ByPrice
ByPrice.propTypes = {
  locationId: PropTypes.string,
};
export default ByPrice;
