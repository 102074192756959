/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { Typography } from "@mui/material";

const VenuesPicker = ({ venues, setFieldValue, venuesV }) => {
  const filterRec = (data) =>
    data.map((venueData) => ({
      label: venueData.title,
      value: venueData._id,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      let url = `${process.env.REACT_APP_API}/venues?`;
      if (inputValue) url += `title=${inputValue}`;
      url += "&sort=title&dir=asc";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          resolve(filterRec(result.data));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.message);
        });
    });

  return (
    <div>
      <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {venues.label}
      </Typography>{" "}
      <AsyncSelect
        // ref={selectRef}
        isMulti
        cacheOptions
        defaultOptions
        defaultValue={venuesV}
        // defaultInputValue={recId}
        placeholder="Search for a venue"
        // key={venue}
        menuPosition="fixed"
        // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        // menuShouldScrollIntoView
        loadOptions={promiseOptions}
        onChange={(options) => {
          // Handle an array of selected options
          if (!options || options.length === 0) {
            setFieldValue("venues", []);
          } else {
            setFieldValue("venues", options);
            // handleChange(options);
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            // border: "none!important",
            // boxShadow: "none",
            height: "40px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          multiValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
      />
    </div>
  );
};
// Setting default props
VenuesPicker.defaultProps = {
  setFieldValue: () => {},
  venuesV: [],
};

VenuesPicker.propTypes = {
  venues: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func,
  venuesV: PropTypes.instanceOf(Array),
};

export default VenuesPicker;
