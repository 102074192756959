/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";
import ItineraryInfo from "pages/content/experiences/new-experience/components/ItineraryInfo";
import ItineraryStops from "pages/content/experiences/new-experience/components/IntineraryStops";

const ItineraryDetail = ({
  type,
  formData,
  detailPage,
  setDetailPage,
  handleEdit,
  handleCuratorChange,
  selectedCurator,
  expId,
  handleSlotChange,
  selectedSlot,
  handleTicketChange,
  selectedTickets,
  handleMediaChange,
  tabValue,
  setTabValue,
}) => {
  const { submitForm, resetForm } = useFormikContext();

  const handleCardTitle = () => {
    if (type === "itineraryInfo") {
      return "Itinerary Info";
    }
    return "Stops";
  };

  const handleInfoCard = () => {
    if (type === "itineraryInfo") {
      return (
        <ItineraryInfo
          formData={formData}
          editPage
          detailPage={detailPage}
          handleCuratorChange={handleCuratorChange}
          selectedCurator={selectedCurator}
        />
      );
    }
    return (
      <ItineraryStops
        formData={formData}
        editPage
        id={expId}
        handleSlotChange={handleSlotChange}
        selectedSlot={selectedSlot}
        handleTicketChange={handleTicketChange}
        selectedTickets={selectedTickets}
        handleMediaChange={handleMediaChange}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
    );
  };

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>
        <ViewEditActions
          noEditIcon={type === "stops"}
          detailPage={detailPage}
          handleEdit={handleEdit}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      <MDBox>{handleInfoCard()}</MDBox>
    </MDBox>
  );
};

// Setting default props
ItineraryDetail.defaultProps = {
  detailPage: true,
  setDetailPage: () => {},
  handleCuratorChange: () => {},
  selectedCurator: {},
  handleSlotChange: () => {},
  handleTicketChange: () => {},
  selectedTickets: [],
  handleMediaChange: () => {},
};

// typechecking props for ItineraryDetail
ItineraryDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  handleCuratorChange: PropTypes.func,
  selectedCurator: PropTypes.instanceOf(Object),
  expId: PropTypes.string.isRequired,
  handleSlotChange: PropTypes.func,
  selectedSlot: PropTypes.instanceOf(Object).isRequired,
  handleTicketChange: PropTypes.func,
  selectedTickets: PropTypes.instanceOf(Object),
  handleMediaChange: PropTypes.func,
  tabValue: PropTypes.number.isRequired,
  setTabValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default ItineraryDetail;
