/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { Typography } from "@mui/material";

const ItemPicker = ({ item, itemObj, itemType, handleChange, destinations, setFieldValue }) => {
  const filterRec = (data) =>
    data.map((itemData) => ({
      label: itemData.title,
      value: itemData._id,
    }));

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      return new Promise((resolve) => {
        const later = () => {
          clearTimeout(timeout);
          resolve(func.apply(this, args));
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      });
    };
  }

  const fetchOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/tickets?`;
      if (destinations && destinations.length > 0) {
        const destinationIds = destinations.map((dest) => dest.value).join(",");
        url += `destinations=${destinationIds}&`;
      }
      if (inputValue) url += `title=${inputValue}`;
      url += "&sort=title";
      url += "&dir=asc";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          resolve(filterRec(result.data));
        })
        .catch((error) => console.log(error.message));
    });

  const debouncedFetchOptions = debounce(fetchOptions, 1000);

  // Usage in your picker component
  const promiseOptions = (inputValue) => debouncedFetchOptions(inputValue);

  return (
    <div>
      <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {item.label}
      </Typography>{" "}
      <AsyncSelect
        // ref={selectRef}
        cacheOptions
        defaultOptions
        // defaultValue={recId}
        // defaultInputValue={recId}
        placeholder={itemObj.label ? itemObj.label : "Search for an Item"}
        menuPosition="fixed"
        loadOptions={promiseOptions}
        onChange={(option) => {
          if (!option) {
            setFieldValue(null);
          } else if (itemType === "item") {
            setFieldValue("item", option?.value);
            handleChange(option);
          } else {
            setFieldValue(itemType, option?.value);
            handleChange(option);
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            // border: "none!important",
            // boxShadow: "none",
            height: "40px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
      />
    </div>
  );
};

// Setting default props
ItemPicker.defaultProps = {
  itemObj: {},
  itemType: "item",
  handleChange: () => {},
  destinations: [],
  item: {},
};

// typechecking props for ItemPicker
ItemPicker.propTypes = {
  item: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  itemType: PropTypes.string,
  destinations: PropTypes.instanceOf(Array),
  setFieldValue: PropTypes.func.isRequired,
  itemObj: PropTypes.instanceOf(Object),
};

export default ItemPicker;
