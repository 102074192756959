import React from "react";
import { Column, Img, Row, Section, Text } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";

const IfYouNeedSupportSection = () => {
  // to prevent blinking when user press on the email to open the external email App
  const handleEmailClick = (e) => {
    e.preventDefault();
    window.location.href = "mailto:cs@lokalee.app";
  };

  return (
    <Tailwind>
      <Text className="text-[20px] leading-[23px] font-bold text-black text-center mb-2">
        If you need any assistance:
      </Text>
      <Section className="bg-[#BAB9B9] p-0.5 text-center text-white">
        <div className="flex flex-col items-center my-0">
          <Img
            src="https://email-assets.lokalee.app/email/emailImages/inbox.png"
            alt="Inbox"
            className="w-[20px] h-[33px] pt-4 my-0"
          />
          <Text className="text-[11px] leading-[15px] font-bold text-white my-1">
            Email us:
            <Row className="justify-center my-1">
              <Column>
                <a
                  href="mailto:cs@lokalee.app"
                  onClick={handleEmailClick}
                  className="text-[11px] leading-[15px] font-bold text-white"
                >
                  cs@lokalee.app
                </a>
              </Column>
            </Row>
          </Text>
        </div>
      </Section>
    </Tailwind>
  );
};

export default IfYouNeedSupportSection;
