/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios from "axios";

import {
  SET_ITINERARY_LOADING,
  CLEAR_ERRORS,
  ITINERARY_STOPS_FAIL,
  ITINERARY_STOP_ADD_SUCCESS,
} from "./Types";

// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Set Loading
export const setLoading = () => ({ type: SET_ITINERARY_LOADING });

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Get All Itineraries
export const getItineraries = () =>
  factory.get(
    `${URL}/experiences?fields=title,label,curator,days&sort=createdAt&dir=asc&aiGenerated=false`,
    "GET_ALL_ITINERARIES",
    "ITINERARY_FAIL"
  );

// Add Itinerary
export const addItinerary = (data) =>
  factory.post(
    {
      title: data.title?.trim(),
      description: data.introText,
      label: data.travelStyle,
      curator: data.curator?.value,
      days: data.days,
    },
    `${URL}/experiences`,
    "ITINERARY_ADD_SUCCESS",
    "ITINERARY_FAIL"
  );

// Delete Itinerary - set active as false
export const deleteItinerary = (id) =>
  factory.patch(
    { active: false },
    `${URL}/experiences/${id}`,
    "DELETE_SINGLE_ITINERARY",
    "ITINERARY_FAIL"
  );

// get One Itinerary
export const getOneItinerary = (id) =>
  factory.get(`${URL}/experiences/${id}`, "GET_ONE_ITINERARY", "ITINERARY_FAIL");

// Add Itinerary
export const updateItinerary = (data) =>
  factory.patch(
    {
      title: data.title?.trim(),
      description: data.introText,
      label: data.travelStyle,
      curator: data.curator?.value,
      days: data.days,
    },
    `${URL}/experiences/${data?._id}`,
    "UPDATE_ITINERARY",
    "ITINERARY_FAIL"
  );

// Add Stop
export const addStop = (id, tabValue, data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  const ids =
    data?.type === "tickets"
      ? data.tickets.map((ticket) => ticket?.value)
      : data.venues.map((venue) => venue?.value);

  form.append("day", tabValue + 1);
  form.append("slot", data.slot);
  form.append("description", data.description);
  if (data.type?.length > 0) {
    form.append("type", data.type === "tickets" ? "Ticket" : "Venue");
  } else {
    form.append("type", "cta");
  }
  if (data?.type === "tickets" || data?.type === "venues") {
    ids?.map((itemId) => {
      if (itemId !== "") {
        form.append("ids", itemId.trim());
        return itemId;
      }
      return null;
    });
  } else {
    form.append("ctaTitle", data.ctaTitle);
    form.append("ctaLabel", data.ctaLabel);
    form.append("ctaUrl", data.ctaUrl);
  }
  if (data?.media === "video") form.append("video", data?.video);
  if (data?.media === "image") {
    data.image?.map((file) => {
      form.append("media", file);
      return file;
    });
  }

  try {
    const res = await axios.post(`${URL}/experiences/${id}/stop`, form, config);

    dispatch({
      type: ITINERARY_STOP_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ITINERARY_STOPS_FAIL,
      payload: err,
    });
  }
};

// Delete Stop - set active as false
export const deleteStop = (id, stopId) =>
  factory.patch(
    { active: false, stopId },
    `${URL}/experiences/${id}/stop`,
    "DELETE_SINGLE_STOP",
    "ITINERARY_STOPS_FAIL"
  );
