/* eslint-disable react/no-array-index-key */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import DetailList from "components/DetailList";
import { useEffect, useState } from "react";
import { areAllValuesEmpty } from "components/helper";

const Review = ({ formData, selectedItem, selectedRecommendaion }) => {
  const { values } = formData;
  const [uniqueExperienceInfoData, setUniqueExperienceInfoData] = useState({});
  const [fields, setFields] = useState([]);
  useEffect(() => {
    if (values) {
      setUniqueExperienceInfoData({
        recommendation:
          values?.type === "recommendation" && selectedRecommendaion?.label
            ? values?.recommendation?.title
            : values?.recommendation?.label,
        item: values?.type === "item" && selectedItem?.label,
        trendingStart: values?.trendingStart,
        trendingEnd: values?.trendingEnd,
      });
    }
  }, [values]);

  useEffect(() => {
    setFields([{ id: 0, title: "Unique experience info", data: uniqueExperienceInfoData }]);
  }, [uniqueExperienceInfoData]);

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {fields?.map((field) => (
            <Grid
              item
              key={field.id}
              xs={12}
              sx={{
                display: areAllValuesEmpty(field.data) ? "none" : "flex",
                flexDirection: "column",
              }}
            >
              <DetailList data={field.data} title={field?.title} />
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};
Review.defaultProps = {
  selectedItem: {},
  selectedRecommendaion: {},
};
// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  //   editPage: PropTypes.bool,
  selectedItem: PropTypes.instanceOf(Object),
  selectedRecommendaion: PropTypes.instanceOf(Object),
};

export default Review;
