/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-prop-types */
import React from "react";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";

const AvailabilityDurationPanel = ({ setFieldValue, availabilities }) => {
  // const [listEdited, setListEdited] = useState([]);

  const deleteAvailability = (i) => {
    const arr = availabilities;
    arr.splice(i, 1);
    // setListEdited(arr);
    // setFieldValue("itineraryItems", arr);
    setFieldValue("itineraryItems", []);
    setFieldValue("itinerary.itineraryItems", arr);
  };

  return (
    <Box
      sx={{
        marginTop: "30px",
        marginBottom: "0px",
      }}
    >
      {availabilities?.map((row, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container alignItems="center">
              <Grid item xs="auto">
                <Typography variant="body1" style={{ wordBreak: "break-word", maxWidth: "50ch" }}>
                  {row?.pointOfInterestLocation?.pin?.label ||
                    row?.pointOfInterestLocation?.pin?.id?.name}
                </Typography>
              </Grid>

              <Grid item>
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  edge="end"
                  onClick={() => {
                    deleteAvailability(index);
                    // setFieldValue("availabilities", listEdited);
                  }}
                >
                  <DeleteIcon sx={{ color: "grey" }} />
                </IconButton>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="span" variant="body2">
              {row.duration?.from && (
                <div>
                  {row.duration?.from ? <span style={{ fontWeight: 500 }}>From: </span> : null}
                  {row?.duration?.from}
                  {row?.duration?.to && (
                    <span>
                      <span style={{ fontWeight: 500, marginLeft: "20px" }}>- To: </span>
                      {row.duration?.to}
                    </span>
                  )}
                  {row.duration?.timeType && (
                    <span>
                      <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Time type: </span>
                      {row.duration?.timeType}
                    </span>
                  )}{" "}
                  {row.pointOfInterestLocation?.attractionId && (
                    <span>
                      <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Venue: </span>
                      {row.pointOfInterestLocation?.attractionId?.label ||
                        row.pointOfInterestLocation?.attractionId?.title}
                    </span>
                  )}
                  <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Description: </span>
                  {row?.description}
                </div>
              )}

              {row.duration?.day && (
                <div>
                  {row.duration?.day ? <span style={{ fontWeight: 500 }}>Day: </span> : null}
                  {row?.duration?.day}
                  {row?.duration?.hour && (
                    <span>
                      <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Hour: </span>
                      {row.duration?.hour}
                    </span>
                  )}
                  {row.duration?.minute && (
                    <span>
                      <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Minute: </span>
                      {row.duration?.minute}
                    </span>
                  )}{" "}
                  {row.pointOfInterestLocation?.attractionId && (
                    <span>
                      <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Venue: </span>
                      {row.pointOfInterestLocation?.attractionId?.label ||
                        row.pointOfInterestLocation?.attractionId?.title}
                    </span>
                  )}{" "}
                  <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Description: </span>
                  {row?.description}
                </div>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
AvailabilityDurationPanel.defaultProps = {
  availabilities: [],
};

AvailabilityDurationPanel.propTypes = {
  availabilities: PropTypes.instanceOf(Array),
  setFieldValue: PropTypes.func.isRequired,
};

export default AvailabilityDurationPanel;
