/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";
import LocationInfo from "pages/content/locations/new-location/components/LocationInfo";
import AdditionalInfo from "pages/content/locations/new-location/components/AdditionalInfo";
import Address from "pages/content/locations/new-location/components/Address";
import Sliders from "pages/content/locations/new-location/components/Sliders";
import { FormControlLabel, FormGroup } from "@mui/material";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import GuestCompanion from "pages/content/locations/new-location/Guest-Companion";

const LocationDetail = ({
  locationId,
  formData,
  type,
  partnerObj,
  travelDeskObj,
  detailPage,
  setDetailPage,
  handleEdit,
  handlePartnerChange,
  selectedPartner,
  handleTravelDeskChange,
  selectedTravelDesk,
  handlePrimaryDestinationChange,
  handleSecondaryDestinationChange,
  selectedPrimaryDestination,
  selectedSecondaryDestination,
  setSelectedPrimaryDestination,
  setSelectedPartner,
  setSelectedTravelDesk,
}) => {
  const { submitForm, resetForm, values, setFieldValue } = useFormikContext();
  const handleCardTitle = () => {
    if (type === "locationInfo") {
      return "Location info";
    }
    if (type === "address") {
      return "Address";
    }
    if (type === "sliders") {
      return (
        <FormGroup row>
          <FormControlLabel
            control={
              <GreenSwitch
                checked={values.showSlider}
                name="showSlider"
                value={values.showSlider}
                onChange={(event) => {
                  setFieldValue("showSlider", event.target.checked);
                  setDetailPage(false);
                }}
              />
            }
            label="Sliders"
          />
        </FormGroup>
      );
    }
    if (type === "guestCompanion") {
      return (
        <FormGroup row>
          <FormControlLabel
            control={
              <GreenSwitch
                checked={values.showGuestCompanion}
                name="showGuestCompanion"
                value={values.showGuestCompanion}
                onChange={(event) => {
                  setFieldValue("showGuestCompanion", event.target.checked);
                  setDetailPage(false);
                }}
              />
            }
            label="Guest Companion"
          />
        </FormGroup>
      );
    }
    if (type === "disclaimer") {
      return (
        <FormGroup row>
          <FormControlLabel
            control={
              <GreenSwitch
                checked={values.disclaimer}
                name="disclaimer"
                value={values.disclaimer}
                onChange={(event) => {
                  setFieldValue("disclaimer", event.target.checked);
                  setDetailPage(false);
                }}
              />
            }
            label="Disclaimer"
          />
        </FormGroup>
      );
    }
    return "Additional info";
  };

  const handleInfoCard = () => {
    // console.log("REACT_APP_GOOGLE_API>>>", process.env.REACT_APP_GOOGLE_API);
    if (type === "locationInfo") {
      return (
        <LocationInfo
          formData={formData}
          partnerObj={partnerObj}
          editPage
          detailPage={detailPage}
          handleChange={handlePartnerChange}
          selectedPartner={selectedPartner}
        />
      );
    }
    if (type === "address") {
      return (
        <Address
          formData={formData}
          editPage
          detailPage={detailPage}
          handlePrimaryDestinationChange={handlePrimaryDestinationChange}
          handleSecondaryDestinationChange={handleSecondaryDestinationChange}
          selectedPrimaryDestination={selectedPrimaryDestination}
          selectedSecondaryDestination={selectedSecondaryDestination}
          setSelectedPrimaryDestination={setSelectedPrimaryDestination}
        />
      );
    }
    if (type === "sliders") {
      return <Sliders formData={formData} editPage detailPage={detailPage} id={locationId} />;
    }
    if (type === "guestCompanion") {
      return (
        <GuestCompanion formData={formData} editPage detailPage={detailPage} id={locationId} />
      );
    }
    return (
      <AdditionalInfo
        formData={formData}
        editPage
        travelDeskObj={travelDeskObj}
        detailPage={detailPage}
        handleChange={handleTravelDeskChange}
        selectedTravelDesk={selectedTravelDesk}
      />
    );
  };

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>
        <ViewEditActions
          detailPage={detailPage}
          handleEdit={handleEdit}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
            setSelectedPartner(null);
            setSelectedTravelDesk(null);
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      <MDBox>{handleInfoCard()}</MDBox>
    </MDBox>
  );
};

// Setting default props
LocationDetail.defaultProps = {
  detailPage: true,
  setDetailPage: () => {},
  type: "",
  locationId: "",
  partnerObj: {},
  travelDeskObj: {},
  selectedPartner: {},
  handlePartnerChange: () => {},
  selectedTravelDesk: {},
  handleTravelDeskChange: () => {},
  handlePrimaryDestinationChange: () => {},
  handleSecondaryDestinationChange: () => {},
  selectedPrimaryDestination: {},
  selectedSecondaryDestination: [],
  setSelectedPrimaryDestination: () => {},
  setSelectedPartner: () => {},
  setSelectedTravelDesk: () => {},
};

// typechecking props for LocationDetail
LocationDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  type: PropTypes.string,
  locationId: PropTypes.string,
  partnerObj: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  travelDeskObj: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  selectedPartner: PropTypes.instanceOf(Object),
  handlePartnerChange: PropTypes.func,
  selectedTravelDesk: PropTypes.instanceOf(Object),
  handleTravelDeskChange: PropTypes.func,
  handlePrimaryDestinationChange: PropTypes.func,
  handleSecondaryDestinationChange: PropTypes.func,
  selectedPrimaryDestination: PropTypes.instanceOf(Object),
  selectedSecondaryDestination: PropTypes.instanceOf(Array),
  setSelectedPrimaryDestination: PropTypes.func,
  setSelectedPartner: PropTypes.func,
  setSelectedTravelDesk: PropTypes.func,
};

export default LocationDetail;
