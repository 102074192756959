import { combineReducers } from "redux";

import userReducer from "./userReducer";
import alertReducer from "./alertReducer";
import locationReducer from "./locationReducer";
import partnerReducer from "./partnerReducer";
import cityReducer from "./cityReducer";
import categoryReducer from "./categoryReducer";
import boxReducer from "./boxReducer";
import bannerReducer from "./bannerReducer";
import recommendationReducer from "./recommendationReducer";
import itemReducer from "./itemReducer";
import travelDeskReducer from "./travelDeskReducer";
import attractionReducer from "./attractionReducer";
import experienceReducer from "./experienceReducer";
import promotionReducer from "./promotionReducer";
import newsReducer from "./newsReducer";
import jobReducer from "./jobReducer";
import facilityReducer from "./facilityReducer";
import reportReducer from "./reportReducer";
import widgetReducer from "./widgetReducer";
import menuReducer from "./menuReducer";
import voucherReducer from "./voucherReducer";
import campaignReducer from "./campaignReducer";
import promoCodeReducer from "./promoCodeReducer";
import statsReducer from "./statsReducer";
import filterReducer from "./filterReducer";
import trendReducer from "./uniqueExperienceReducer";
import exportReducer from "./exportReducer";
import tagReducer from "./tagReducer";
import destinationsReducer from "./destinationsReducer";
import venuesReducer from "./venuesReducer";
import serviceJobsReducer from "./serviceJobsReducer";
import itineraryReducer from "./itineraryReducer";

export default combineReducers({
  users: userReducer,
  alerts: alertReducer,
  locations: locationReducer,
  partners: partnerReducer,
  travelDesks: travelDeskReducer,
  menus: menuReducer,
  tags: tagReducer,
  cities: cityReducer,
  categories: categoryReducer,
  boxes: boxReducer,
  trends: trendReducer,
  banners: bannerReducer,
  recommendations: recommendationReducer,
  items: itemReducer,
  vouchers: voucherReducer,
  attractions: attractionReducer,
  experiences: experienceReducer,
  promotions: promotionReducer,
  news: newsReducer,
  jobs: jobReducer,
  facilities: facilityReducer,
  reports: reportReducer,
  widgets: widgetReducer,
  campaigns: campaignReducer,
  promoCodes: promoCodeReducer,
  stats: statsReducer,
  filter: filterReducer,
  exports: exportReducer,
  destinations: destinationsReducer,
  venues: venuesReducer,
  serviceJobs: serviceJobsReducer,
  itineraries: itineraryReducer,
});
