/* eslint-disable no-underscore-dangle */
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import setAlert from "store/actions/alertActions";
import { addItinerary, clearErrors, setLoading, addStop } from "store/actions/itineraryActions";
import { scrollToTop, useHandleError } from "components/helper";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValuesStops from "./schemas/initialValuesStops";
import formStops from "./schemas/formStops";
import ItineraryInfo from "./components/ItineraryInfo";
import ItineraryStops from "./components/IntineraryStops";
import validationsStops from "./schemas/validationsStops";

function getSteps() {
  return ["Info", "Stops"];
}

function getStepContent(
  stepIndex,
  formData,
  expId,
  handleCuratorChange,
  selectedCurator,
  handleSlotChange,
  selectedSlot,
  handleTicketChange,
  selectedTickets,
  handleMediaChange,
  selectedMedia,
  tabValue,
  setTabValue,
  displayErr
) {
  switch (stepIndex) {
    case 0:
      return (
        <ItineraryInfo
          formData={formData}
          handleCuratorChange={handleCuratorChange}
          selectedCurator={selectedCurator}
          displayErr={displayErr}
        />
      );
    case 1:
      return (
        <ItineraryStops
          formData={formData}
          id={expId}
          handleSlotChange={handleSlotChange}
          selectedSlot={selectedSlot}
          handleTicketChange={handleTicketChange}
          selectedTickets={selectedTickets}
          handleMediaChange={handleMediaChange}
          selectedMedia={selectedMedia}
          tabValue={tabValue}
          setTabValue={setTabValue}
        />
      );
    default:
      return null;
  }
}

const NewExperience = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const source = searchParams.get("source");
  const sourceId = searchParams.get("sourceId");

  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [selectedCurator, setSelectedCurator] = useState({});
  const [selectedSlot, setSelectedSlot] = useState({});
  // const [selectedIndex, setSelectedIndex] = useState("");
  const [open, setOpen] = useState(false);
  const [displayErr, setDisplayErr] = useState(false);

  const [selectedTickets, setSelectedTickets] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState("none");
  const [tabValue, setTabValue] = useState(0);

  const { formId, formField } = activeStep === 0 ? form : formStops;
  const isLastStep = activeStep === steps.length - 1;

  const error = useSelector((state) => state.itineraries.error);
  const expId = useSelector((state) => state.itineraries.expId);

  // Initial values for each step
  const initialValuesForSteps = [initialValues, initialValuesStops];
  const validationsForSteps = [validations[0], validationsStops[0]];

  useHandleError(error, dispatch, setAlert, clearErrors);

  useEffect(() => {
    setSteps(getSteps("admin"));
  }, []);

  const handleCuratorChange = (val) => {
    if (val) {
      setSelectedCurator(val);
    }
  };

  const handleSlotChange = (val) => {
    if (val) {
      setSelectedSlot(val);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleStep = (step) => {
  //   setActiveStep(step);
  // };

  const handleTicketChange = (value) => {
    if (value) {
      setSelectedTickets(value);
    } else {
      setSelectedTickets([]);
    }
  };

  const handleMediaChange = (value) => {
    if (value) {
      setSelectedMedia(value);
    } else {
      setSelectedMedia("none");
    }
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // const handleBack = () => {
  //   setActiveStep(activeStep - 1);
  //   scrollToTop();
  // };

  const submitForm = async (values, actions) => {
    await sleep(1000);
    dispatch(setLoading());
    if (activeStep === 0) {
      dispatch(addItinerary(values));
      setActiveStep(1);
      setSelectedCurator(null);
    }
    if (activeStep === 1) {
      dispatch(addStop(expId, tabValue, values));
      actions.resetForm();
    }
    actions.setSubmitting(false);
    // actions.resetForm();
    // setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);

    if (isLastStep) {
      // actions.resetForm();
    }

    // setActiveStep(activeStep + 1);
    actions.setTouched({});
    actions.setSubmitting(false);
    scrollToTop();
  };

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setValues(initialValuesForSteps[activeStep]);
    }
  }, [activeStep]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValuesForSteps[activeStep]}
              validationSchema={
                steps[activeStep] === "Review" ? undefined : validationsForSteps[activeStep]
              }
              enableReinitialize
              onSubmit={handleSubmit}
              innerRef={(f) => {
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step
                            key={label}
                            // onClick={() => {
                            //   if (dirty) {
                            //     setOpen(true);
                            //     setSelectedIndex(index);
                            //   } else {
                            //     handleStep(index);
                            //   }
                            // }}
                          >
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          },
                          expId,
                          handleCuratorChange,
                          selectedCurator,
                          handleSlotChange,
                          selectedSlot,
                          handleTicketChange,
                          selectedTickets,
                          handleMediaChange,
                          selectedMedia,
                          tabValue,
                          setTabValue,
                          displayErr
                        )}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {/* {activeStep === 0 ? ( */}
                          <MDBox />
                          {/* ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )} */}
                          <div>
                            <MDButton
                              onClick={() =>
                                navigate(
                                  source === "users"
                                    ? `/admin/users/${sourceId}`
                                    : "/admin/experiences"
                                )
                              }
                              rel="noreferrer"
                              variant="outlined"
                              color="dark"
                              sx={{ marginRight: "8px" }}
                            >
                              Cancel
                            </MDButton>
                            <MDButton
                              onClick={() => {
                                setDisplayErr(true); // Set the display error only when clicked
                              }}
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "Add" : "Save & Continue"}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>

        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Unsaved Fields </DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you don&apos;t want to save the changes?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton
                onClick={() => {
                  // handleStep(selectedIndex);
                  handleClose();
                }}
              >
                Yes
              </MDButton>
              <MDButton onClick={handleClose} autoFocus>
                No
              </MDButton>
            </DialogActions>
          </Dialog>
        </div>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewExperience;
