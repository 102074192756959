/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { EditorState, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TranslateIcon from "@mui/icons-material/Translate";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TokenIcon from "@mui/icons-material/Token";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";
import ViewEditActions from "components/DetailList/ViewEditActions";
import DynamicTranslate from "components/DynamicTranslate";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import {
  updateRecommendation,
  clearErrors,
  getOneRecommendation,
  updateRecommendationTranslations,
  setLoading,
} from "store/actions/recommendationActions";
import setAlert from "store/actions/alertActions";
import {
  setLoading as setVoucherLoading,
  getRecommednationVoucher,
} from "store/actions/voucherActions";

// Schemas
import CardsList from "components/CardsList";
import { clearErrors as clearCityErrors } from "store/actions/cityActions";
import { resetFormToInitialState } from "components/helper";
import validations from "./schemas/validations";
import form from "../new-recommendation/schemas/form";
import initialValues from "../new-recommendation/schemas/initialValues";
import RecommendationDetail from "./components/RecommendationDetail";

const EditRecommendation = () => {
  const { formId, formField } = form;
  const { recommendation_id } = useParams();

  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const recommendation = useSelector((state) => state.recommendations.recommendation);
  const error = useSelector((state) => state.recommendations.error);
  const added = useSelector((state) => state.recommendations.added);
  const user = useSelector((state) => state.users.user);
  const vouchers = useSelector((state) => state?.vouchers?.vouchers);
  const vouchersLoading = useSelector((state) => state?.vouchers?.loading);

  // state
  const [initialState, setInitialState] = useState(initialValues);
  const [image, _setImage] = useState(recommendation?.logo);
  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [formRefVal, setFormRefVal] = useState(null);
  const [recommendationDetailPage, setRecommendationDetailPage] = useState(true);
  const [dateAndTimeDetailPage, setDateAndTimeDetailPage] = useState(true);
  const [additionalDetailPage, setAdditionalDetailPage] = useState(true);
  const [addressDetailPage, setAddressDetailPage] = useState(true);
  const [tripPlannerDetailPage, setTripPlannerDetailPage] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [translateItems, setTranslateItems] = useState([]);
  const [selectedPrimaryDestination, setSelectedPrimaryDestination] = useState(null);
  const [selectedSecondaryDestination, setSelectedSecondaryDestination] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const detailTabs = [
    { id: 1, title: "RECOMMENDATION DETAILS", iconComponent: AssignmentIcon },
    { id: 2, title: "VOUCHERS", iconComponent: TokenIcon },
    { id: 3, title: "TRANSLATE", iconComponent: TranslateIcon },
  ];

  const currentValidation = !recommendationDetailPage
    ? validations[0]
    : !addressDetailPage
    ? validations[1]
    : !additionalDetailPage
    ? validations[2]
    : !dateAndTimeDetailPage
    ? validations[3]
    : !tripPlannerDetailPage
    ? validations[4]
    : undefined;

  useEffect(() => {
    if (formRefVal?.city) {
      dispatch(clearCityErrors());
    }
  }, [formRefVal, formRefVal?.city]);
  // useEffect
  useEffect(() => {
    if (recommendation_id) {
      dispatch(getOneRecommendation(recommendation_id));
    }
  }, [recommendation_id, added]);

  useEffect(() => {
    if (recommendation) {
      const arTranslation = recommendation?.translations?.ar;

      const arr = [
        {
          id: 1,
          name: "title",
          title: "Title",
          value: arTranslation?.title || "",
          default: "",
          label: "Title",
        },
        {
          id: 2,
          name: "subHeader",
          title: "SubHeader",
          value: arTranslation?.subHeader || "",
          default: "",
          label: "SubHeader",
        },
        {
          id: 3,
          name: "description",
          title: "Description",
          value: arTranslation?.description || "",
          default: "",
          label: "Description",
        },
      ];
      setTranslateItems(arr);
    }
  }, [recommendation]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
      // Reset the form to its initial state
      resetFormToInitialState(formRef, initialState);
    }
  }, [error, setAlert, clearErrors]);

  useEffect(() => {
    if (recommendation?.logo) {
      _setImage(recommendation?.logo);
    }
  }, [recommendation?.logo]);

  // We cant use the recommendation as is because of address picker and map picker
  useEffect(() => {
    const fetchData = async () => {
      if (recommendation) {
        const manipulated = {};
        manipulated.title = recommendation?.title ? recommendation?.title : "";
        manipulated.subHeader = recommendation?.subHeader ? recommendation?.subHeader : "";
        manipulated.description = recommendation?.description ? recommendation?.description : "";

        // manipulated.logo = recommendation?.logo ? recommendation?.logo : "";
        manipulated.location = recommendation?.location ? recommendation?.location : "";

        manipulated.address = recommendation?.geoLocationData.address;
        manipulated.city = recommendation?.geoLocationData?.city;
        manipulated.region = recommendation.geoLocationData.region
          ? recommendation.geoLocationData.region
          : "";
        manipulated.country = recommendation.geoLocationData.country
          ? recommendation.geoLocationData.country
          : "";
        manipulated.lat = recommendation.geoLocation?.coordinates[1]
          ? recommendation.geoLocation?.coordinates[1]
          : "";
        manipulated.lng = recommendation.geoLocation?.coordinates[0]
          ? recommendation.geoLocation?.coordinates[0]
          : "";
        manipulated.primaryDestination = recommendation?.primaryDestination
          ? recommendation?.primaryDestination
          : "";
        manipulated.destinations = recommendation?.destinations
          ? recommendation?.destinations?.map((destination) => ({
              label: destination?.destinationName,
              value: destination?._id,
              type: destination.destinationType,
            }))
          : [];
        manipulated.style = recommendation?.style ? recommendation?.style : [];
        manipulated.interests = recommendation?.interests ? recommendation?.interests : [];
        manipulated.kidsFriendly = recommendation?.kidsFriendly
          ? recommendation?.kidsFriendly
          : false;
        manipulated.timeSlotPreference = recommendation?.timeSlotPreference
          ? recommendation?.timeSlotPreference
          : "";
        manipulated.seasonality = recommendation?.seasonality ? recommendation?.seasonality : "";
        manipulated.genderPreference = recommendation?.genderPreference
          ? recommendation?.genderPreference
          : "";

        manipulated.images = recommendation.images.length > 0 ? recommendation.images : [];
        manipulated.email = recommendation?.email ? recommendation?.email : "";
        manipulated.phone = recommendation?.phone ? recommendation?.phone : "";
        manipulated.webAddresses = recommendation?.webAddresses ? recommendation?.webAddresses : "";
        manipulated.features = recommendation?.features ? recommendation?.features : "";
        manipulated.cuisines = recommendation?.cuisines ? recommendation?.cuisines : [];
        manipulated.openHours = recommendation.openHours ? recommendation.openHours : [];
        manipulated.tags = recommendation?.tags
          ? recommendation?.tags?.map((tag) => ({
              label: tag?.title,
              value: tag?._id,
            }))
          : [];
        manipulated.callToAction = recommendation?.callToAction ? recommendation?.callToAction : {};
        manipulated.callToAction2 = recommendation?.callToAction2
          ? recommendation?.callToAction2
          : {};
        manipulated.price = recommendation?.price ? recommendation?.price : "";

        manipulated.sponsored = recommendation?.sponsored ? recommendation?.sponsored : false;
        manipulated.currated = recommendation?.currated ? recommendation?.currated : false;
        manipulated.highlight = recommendation?.highlight ? recommendation?.highlight : false;
        manipulated.special = recommendation?.special ? recommendation?.special : false;
        manipulated.experienceX = recommendation?.experienceX ? recommendation?.experienceX : false;
        manipulated.exclusive = recommendation?.exclusive ? recommendation?.exclusive : false;
        manipulated.insider = recommendation?.insider ? recommendation?.insider : false;
        manipulated.exclusiveLocation = recommendation?.exclusiveLocation
          ? recommendation?.exclusiveLocation
          : false;
        manipulated.exclusivePartner = recommendation?.exclusivePartner
          ? recommendation?.exclusivePartner
          : false;
        manipulated.top = recommendation?.top ? recommendation?.top : false;
        manipulated.traveltype = recommendation?.traveltype
          ? recommendation?.traveltype
          : "leisure";
        manipulated.badges = recommendation?.badges[0] || null;

        manipulated._id = recommendation?._id;
        _setImage(recommendation?.logo || recommendation?.cover_image_url);

        setInitialState(manipulated);
      }
      setSelectedTags(recommendation?.tags);
      setSelectedLocation(recommendation?.location);
    };
    fetchData();
  }, [recommendation]);

  const urlToBinary = async (url) => {
    const response = await fetch(`${url}?additional-param`);
    if (!response.ok) {
      throw new Error(
        `Failed to fetch image from ${url}. Status: ${response.status} ${response.statusText}`
      );
    }
    const arrayBuffer = await response.arrayBuffer();
    return new Uint8Array(arrayBuffer);
  };

  const blobToBinary = async (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(new Uint8Array(reader.result));
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });

  const handleTagsChange = (val) => {
    if (val) {
      setSelectedTags(val);
    } else {
      setSelectedTags([]);
    }
  };

  const convertImage = async (source) => {
    try {
      let binaryData;

      if (typeof source === "string") {
        // Source is a URL
        binaryData = await urlToBinary(source);
      } else if (source instanceof File) {
        // Source is a File object
        const blob = await blobToBinary(source);
        binaryData = blob;
      } else {
        throw new Error("Unsupported image source type");
      }

      // Create a File object
      const fileName = source instanceof File ? source.name : "defaultImage.jpg";
      const file = new File([binaryData], fileName, { type: "image/jpeg" }); // Update type accordingly

      return file;
    } catch (err) {
      console.error(`Error loading image from ${source}:`, error);
      throw error;
    }
  };

  const processImages = async (imageSources) => {
    try {
      const binaryImages = await Promise.all(imageSources.map(convertImage));
      return binaryImages;
    } catch (err) {
      console.error("Error processing images:", error);
      throw error;
    }
  };

  const submitForm = async (values) => {
    try {
      dispatch(setLoading());

      // Remove deleted images from the recommendation?.images array
      const updatedImages = values.images.filter((newImage) => !newImage.deleted);

      // Process the updated images as binary data
      const processedImages = await processImages(updatedImages);

      // Update the values with the processed images
      const updatedValues = { ...values, images: processedImages };

      if (updatedValues?.title === recommendation?.title) delete updatedValues?.title;

      // Dispatch the update action with the updated values
      dispatch(updateRecommendation(updatedValues, values?.logo));

      setRecommendationDetailPage(true);
      setAdditionalDetailPage(true);
      setAddressDetailPage(true);
      setDateAndTimeDetailPage(true);
      setTripPlannerDetailPage(true);

      dispatch(setLoading());
    } catch (err) {
      console.error("Error processing images:", err);
      // Handle the error appropriately, e.g., show a message to the user
      dispatch(setLoading());
    }
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleSubmitTranslate = (values) => {
    dispatch(updateRecommendationTranslations(values, recommendation?._id));
  };

  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return (
          <CardsList data={vouchers} loading={vouchersLoading} url={`/${user.role}/vouchers`} />
        );
      case 2:
        return (
          <DynamicTranslate
            items={translateItems}
            handleSubmit={handleSubmitTranslate}
            id={recommendation?._id}
          />
        );

      default:
        return null;
    }
  };

  const handleLocationChange = (value) => {
    if (value && value?._id) {
      setSelectedLocation(value);
    } else {
      setSelectedLocation({});
    }
  };
  const handlePrimaryDestinationChange = (value) => {
    if (!value || !value.label) {
      setSelectedPrimaryDestination({});
    } else {
      setSelectedPrimaryDestination(value);
    }
  };
  const handleSecondaryDestinationChange = (value) => {
    if (value) {
      setSelectedSecondaryDestination(value);
    } else {
      setSelectedSecondaryDestination([]);
    }
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOneRecommendation(recommendation_id));
    }
    if (newValue === 1) {
      dispatch(setVoucherLoading());
      dispatch(getRecommednationVoucher(recommendation_id));
    }
  };

  function getStepContent(type, formData) {
    let detailPage;
    let setDetailPage;

    switch (type) {
      case "recommendationInfo":
        detailPage = recommendationDetailPage;
        setDetailPage = setRecommendationDetailPage;
        break;
      case "address":
        detailPage = addressDetailPage;
        setDetailPage = setAddressDetailPage;
        break;
      case "dateAndTimeInfo":
        detailPage = dateAndTimeDetailPage;
        setDetailPage = setDateAndTimeDetailPage;
        break;

      default:
        detailPage = additionalDetailPage;
        setDetailPage = setAdditionalDetailPage;
    }

    const handleEdit = () => {
      formData.resetForm();
      if (type === "recommendationInfo") {
        setRecommendationDetailPage(false);
        setAddressDetailPage(true);
        setAdditionalDetailPage(true);
        setDateAndTimeDetailPage(true);
        setTripPlannerDetailPage(true);
      }
      if (type === "address") {
        setAddressDetailPage(false);
        setRecommendationDetailPage(true);
        setAdditionalDetailPage(true);
        setDateAndTimeDetailPage(true);
        setTripPlannerDetailPage(true);
      }
      if (type === "additionalInfo") {
        setAdditionalDetailPage(false);
        setRecommendationDetailPage(true);
        setAddressDetailPage(true);
        setDateAndTimeDetailPage(true);
        setTripPlannerDetailPage(true);
      }
      if (type === "dateAndTimeInfo") {
        setDateAndTimeDetailPage(false);
        setAdditionalDetailPage(true);
        setRecommendationDetailPage(true);
        setAddressDetailPage(true);
        setTripPlannerDetailPage(true);
      }

      setImgDetailPage(true);
    };

    return (
      <RecommendationDetail
        type={type}
        formData={formData}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        handleEdit={handleEdit}
        selectedLocation={selectedLocation}
        handleLocationChange={handleLocationChange}
        handlePrimaryDestinationChange={handlePrimaryDestinationChange}
        handleSecondaryDestinationChange={handleSecondaryDestinationChange}
        selectedPrimaryDestination={selectedPrimaryDestination}
        selectedSecondaryDestination={selectedSecondaryDestination}
        setSelectedPrimaryDestination={setSelectedPrimaryDestination}
        handleTagsChange={handleTagsChange}
        selectedTags={selectedTags}
      />
    );
  }

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/recommendations?`;
      navigate(url);
    } else if (type === "partner") {
      url = `/partner/recommendations?locationId=${recommendation?.location?._id}`;
      navigate(url);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                setFormRefVal(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <DynamicHeader
                      user={recommendation}
                      image={image}
                      imgDetailPage={imgDetailPage}
                      detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      imageType="logo"
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      {" "}
                      {tabValue === 0 && (
                        <ViewEditActions
                          detailPage={imgDetailPage}
                          stateName="recommendations"
                          handleEdit={() => {
                            setImgDetailPage(false);
                            setDateAndTimeDetailPage(true);
                            setAdditionalDetailPage(true);
                            setRecommendationDetailPage(true);
                            setAddressDetailPage(true);
                            setTripPlannerDetailPage(true);
                          }}
                          handleClose={() => {
                            _setImage(recommendation?.logo);
                            setImgDetailPage(true);
                          }}
                          handleSave={() => {
                            submitForm(values);
                            setImgDetailPage(true);
                          }}
                        />
                      )}
                    </DynamicHeader>
                    {tabValue === 0 ? (
                      <>
                        {["recommendationInfo", "address", "additionalInfo", "dateAndTimeInfo"].map(
                          (item, index) => (
                            <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                              <MDBox p={2}>
                                <Grid container spacing={3} alignItems="center">
                                  <Grid item xs={12}>
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                      {getStepContent(item, {
                                        values,
                                        touched,
                                        formField,
                                        errors,
                                        setFieldValue,
                                        resetForm,
                                      })}
                                    </MDBox>
                                  </Grid>
                                </Grid>
                              </MDBox>
                            </Card>
                          )
                        )}
                      </>
                    ) : (
                      handleDetailData()
                    )}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditRecommendation;
