const form = {
  formId: "new-user-form",
  formField: {
    logo: {
      name: "logo",
      label: "Logo *",
      type: "text",
      errorMsg: "Logo is required!",
    },
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },
    type: {
      name: "type",
      label: "Type *",
      type: "text",
      errorMsg: "Type is required!",
    },
    destinations: {
      name: "destinations",
      label: "Destinations *",
      type: "text",
      errorMsg: "Destination is required!",
      invalidMsg: "Should have at least 1 destination!",
    },
    locations: {
      name: "locations",
      label: "Locations *",
      type: "text",
      errorMsg: "Locations is required!",
      invalidMsg: "Should have at least 1 location!",
    },
    option: {
      name: "option",
      label: "Option *",
      type: "text",
      errorMsg: "Option is required!",
    },
    tags: {
      name: "tags",
      label: "Tags *",
      type: "text",
      errorMsg: "Tag is required!",
      invalidMsg: "Should have at least 1 tag!",
    },
    url: {
      name: "url",
      label: "External URL *",
      type: "url",
      errorMsg: "Url is required!",
      invalidMsg: "Please enter a valid cta url! (http/https url)!",
    },
    top: {
      name: "top",
      label: "Top",
      type: "boolean",
    },
    hidden: {
      name: "hidden",
      label: "Hidden",
      type: "boolean",
    },
    expandable: {
      name: "expandable",
      label: "Expandable to box",
      type: "boolean",
    },
    filters: {
      name: "filters",
      label: "Filters",
      type: "text",
    },
    filterBy: {
      name: "filterBy",
      label: "Filter by",
      type: "text",
    },
    tickets: {
      name: "tickets",
      label: "Tickets *",
      type: "text",
      errorMsg: "Tickets is required!",
    },
  },
};

export default form;
