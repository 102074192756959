// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import PropTypes from "prop-types";
import badgesList from "./BadgeList";

const BadgePicker = ({ badge, badgeV, setFieldValue, ...rest }) => {
  const findBadge = (badgeValue) =>
    badgesList.find((badgeVal) => badgeVal.value === badgeValue) || null;

  const badgeValue = typeof badgeV === "string" ? findBadge(badgeV) : badgeV;

  return (
    <Autocomplete
      id="roles"
      options={badgesList}
      onChange={(e, value) => {
        if (value) {
          setFieldValue("badges", [value]);
        } else {
          setFieldValue("badges", []);
        }
      }}
      value={badgeValue || null}
      getOptionValue={(option) => `${option.value}`}
      getOptionLabel={(option) => `${option.label}`}
      renderInput={(params) => (
        <FormField
          {...params}
          {...rest}
          // InputLabelProps={{ shrink: true }}
          label={badge?.label}
          name={badge?.name}
          // onChange={() => null}
          onChange={params.onChange}
        />
      )}
    />
  );
};

// typechecking props for BadgePicker
BadgePicker.propTypes = {
  badge: PropTypes.instanceOf(Object).isRequired,
  badgeV: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default BadgePicker;
