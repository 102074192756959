/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { Avatar, Divider } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import DetailList from "components/DetailList";
import { useEffect, useState } from "react";
import { areAllValuesEmpty } from "components/helper";
// import { useSelector } from "react-redux";

const Review = ({ formData, editPage }) => {
  const { values } = formData;
  const [destinationInfoData, setDestinationInfoData] = useState({});
  const [addressInfoData, setAddressInfoData] = useState({});

  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (values) {
      setDestinationInfoData({
        destinationName: values?.destinationName,
        type: values?.destinationType,
        parent: values?.parentId?.label,
        Top: values?.top,
        Highlight: values?.highlight,
      });
      setAddressInfoData({
        Address: values?.address,
        Country: values?.country,
        City: values?.city,
        Lat: values?.lat,
        Lng: values?.lng,
        CurrencyCode: values?.defaultCurrencyCode,
        IataCode: values?.iataCode,
        TimeZone: values?.timeZone,
        countryCode: values?.address?.length > 0 ? values?.country_code : values?.countryCode,
      });
    }
  }, [values]);

  useEffect(() => {
    setFields([
      { id: 0, title: "Destination info", data: destinationInfoData },
      { id: 1, title: "Address", data: addressInfoData },
    ]);
  }, [destinationInfoData, addressInfoData]);

  const BigAvatar = styled(Avatar)`
    width: 74px;
    height: 74px;
  `;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {!editPage ? (
            <Grid item xs={12} sm={6}>
              {values?.logo ? (
                <MDAvatar
                  src={URL.createObjectURL(values?.logo)}
                  alt="profile-image"
                  size="xl"
                  sx={{ "& img": { height: "100%" } }}
                />
              ) : (
                <BigAvatar
                  alt="Change your profile photo"
                  src={URL.revokeObjectURL(values?.logo)}
                  imgProps={{
                    style: {
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "cover",
                    },
                  }}
                />
              )}
            </Grid>
          ) : (
            ""
          )}
          {fields?.map((field, i) => (
            <Grid
              item
              key={field.id}
              xs={12}
              sx={{
                display: areAllValuesEmpty(field.data) ? "none" : "flex",
                flexDirection: "column",
              }}
            >
              <DetailList data={field.data} title={field?.title} />
              {i + 1 !== fields?.length && (
                <div>
                  <Divider sx={{ height: "1px" }} />
                </div>
              )}{" "}
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// Setting default props for the Review
Review.defaultProps = {
  editPage: false,
};

// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
};

export default Review;
