/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-prop-types */
import React, { useState } from "react";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";

const AvailabilityPickupPanel = ({ setFieldValue, availabilities }) => {
  const [listEdited, setListEdited] = useState([]);
  const deleteAvailability = (i) => {
    const arr = availabilities.pins;
    arr.splice(i, 1);
    setListEdited(arr);
  };

  return (
    <Box
      sx={{
        marginTop: "30px",
        marginBottom: "0px",
      }}
    >
      {availabilities?.pins?.map((row, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container alignItems="center">
              <Grid item xs="auto">
                <Typography variant="body1" style={{ wordBreak: "break-word", maxWidth: "50ch" }}>
                  {row?.pin?.label || row?.pin?.id?.name}
                </Typography>
              </Grid>

              <Grid item>
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  edge="end"
                  onClick={() => {
                    deleteAvailability(index);
                    setFieldValue("availabilities", listEdited);
                  }}
                >
                  <DeleteIcon sx={{ color: "grey" }} />
                </IconButton>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="span" variant="body2">
              <div>
                {row?.pickupType ? <span style={{ fontWeight: 500 }}>Pickup type: </span> : null}
                {row?.pickupType}
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
AvailabilityPickupPanel.defaultProps = {
  availabilities: [],
};

AvailabilityPickupPanel.propTypes = {
  availabilities: PropTypes.instanceOf(Array),
  setFieldValue: PropTypes.func.isRequired,
};

export default AvailabilityPickupPanel;
