/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import { Autocomplete, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { capitalizeFirstLetter } from "components/helper";
import PropTypes from "prop-types";

const StatusPicker = ({ handleTypeChange, setStatus, status, statusV, setFieldValue, ...rest }) => {
  const statusOptions = ["purchase", "refund"];

  const CssTextField = styled(TextField)({
    "& label": {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 0.9,
      height: "-webkit-fill-available",
    },
    "& .MuiInputLabel-shrink": {
      lineHeight: 1.5,
      fontSize: "1rem",
      fontWeight: 400,
    },
    "& .css-1yoptbl-MuiInputBase-root-MuiInput-root:before, &:hover": {
      borderColor: "#D2D2D2!important",
      borderWidth: "1px !important",
    },
  });

  return (
    <Autocomplete
      id="status"
      filterSelectedOptions
      options={statusOptions}
      onChange={(event, option) => {
        handleTypeChange(option);
        if (option) {
          setFieldValue("type", option.toLowerCase());
          setStatus(option);
        } else {
          setFieldValue("type", "");
          setStatus("");
        }
      }}
      value={statusV ? capitalizeFirstLetter(statusV) : ""}
      getOptionLabel={(option) => option || ""}
      isOptionEqualToValue={(option, value) => option.toLowerCase() === value.toLowerCase()}
      renderInput={(params) => (
        <CssTextField
          {...params}
          {...rest}
          name={status.name}
          variant="outlined"
          label={status.label}
          // margin="normal"
          fontSize="0.875rem"
          maxWidth="100%"
          fullWidth
        />
      )}
    />
  );
};
// Setting default props
StatusPicker.defaultProps = {
  statusV: "",
  status: {},
  setStatus: () => {},
  handleTypeChange: () => {},
};
// typechecking props for StatusPicker
StatusPicker.propTypes = {
  status: PropTypes.instanceOf(Object),
  handleTypeChange: PropTypes.func,
  statusV: PropTypes.string,

  setFieldValue: PropTypes.func.isRequired,
  setStatus: PropTypes.func,
};

export default StatusPicker;
