import React from "react";
import { Row, Section } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
// import AppLinks from "./AppLinks/index";
import SocialLinks from "./SocialLinks/index";
import FooterAddress from "./Address/index";

const FooterSection = () => (
  <Tailwind>
    <Section className="w-full text-center text-xs text-[#888]">
      <Row className="w-full mb-4 justify-center">
        {/* <AppLinks /> */}
        <SocialLinks />
      </Row>
      <FooterAddress />
    </Section>
  </Tailwind>
);

export default FooterSection;
