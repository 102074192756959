/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import ImagePicker from "components/AutoComplete/ImagePicker";
import DetailList from "components/DetailList";
import FeaturesPicker from "components/AutoComplete/FeaturesPicker";
import CuisinesPicker from "components/AutoComplete/CuisinePicker";
// import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "components/helper";
import TagsPicker from "components/AsyncSelect/TagsPicker";
import Danger from "components/Danger";

const Information = ({ formData, editPage, detailPage, selectedTags, handleTagsChange }) => {
  // const dispatch = useDispatch();
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { title, subHeader, description, cuisines, features, tags } = formField;
  const {
    title: titleV,
    subHeader: subHeaderV,
    description: descriptionV,
    // tags: tagsV,
    features: featuresV,
    cuisines: cuisinesV,
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Information</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            title: values?.title,
            subHeader: values?.subHeader,
            description: values?.description,
            tags: values?.tags,
            cuisines: values?.cuisines,
            features: values?.features,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!editPage ? (
              <Grid item xs={12}>
                <ImagePicker edit imageType="logo" />
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={12} sm={6}>
              <FormField
                type={title.type}
                label={title.label}
                name={title.name}
                value={capitalizeFirstLetter(titleV)}
                placeholder={title.placeholder}
                error={errors.title && touched.title}
                success={titleV.length > 0 && !errors.title}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={subHeader.type}
                label={subHeader.label}
                name={subHeader.name}
                value={subHeaderV}
                placeholder={subHeader.placeholder}
                error={errors.subHeader && touched.subHeader}
                success={subHeaderV.length > 0 && !errors.subHeader}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                type={description.type}
                label={description.label}
                name={description.name}
                value={descriptionV}
                multiline
                outlined
                rows={5}
                placeholder={description.placeholder}
                error={errors.description && touched.description}
                success={descriptionV.length > 0 && !errors.description}
              />
            </Grid>
            <Grid item xs={12}>
              <TagsPicker
                tags={tags}
                tagsV={
                  selectedTags?.length > 0 && selectedTags[0]?._id?.length > 0
                    ? selectedTags.map((item) => ({
                        label: item?.title,
                        value: item?._id,
                      }))
                    : selectedTags
                }
                setFieldValue={setFieldValue}
                handleChange={handleTagsChange}
                type="RECOMMENDATIONS"
              />
              <Danger validation={errors?.tags} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CuisinesPicker
                cuisines={cuisines}
                cuisinesV={cuisinesV}
                setFieldValue={setFieldValue}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FeaturesPicker
                features={features}
                featuresV={featuresV}
                setFieldValue={setFieldValue}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};
Information.defaultProps = {
  editPage: false,
  detailPage: true,
  selectedTags: [],
  handleTagsChange: () => {},
};
// typechecking props for Information
Information.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  selectedTags: PropTypes.instanceOf(Object),
  handleTagsChange: PropTypes.func,
};

export default Information;
