/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */

import PropTypes from "prop-types";
import { Divider, Grid, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CopytoClipboard from "components/CopytoClipboard";
import getServicesJobs from "store/actions/serviceJobsActions";
import DetailList from "components/DetailList";

const env = process.env.REACT_APP_ENV;

const GuestCompanion = ({ formData, editPage, detailPage }) => {
  const { values, setFieldValue } = formData;
  const { serviceJobs: serviceJobsV, serviceJobsObj: serviceJobsObjV } = values;
  const dispatch = useDispatch();
  const serviceJobs = useSelector((state) => state.serviceJobs.serviceJobs);
  const url = env === "production" ? "https://lokalee.app" : "https://staging.lokalee.app";

  const [selectedMaintenanceIds, setSelectedMaintenanceIds] = useState([]);
  const [selectedHousekeepingIds, setSelectedHousekeepingIds] = useState([]);
  const [selectedLaundryIds, setSelectedLaundryIds] = useState([]);
  const [selectedLuggageIds, setSelectedLuggageIds] = useState([]);

  // Initialize serviceJobsObj from serviceJobs
  useEffect(() => {
    dispatch(getServicesJobs());

    // Ensure serviceJobsObj is only set once
    if (serviceJobsV && Object.keys(serviceJobsObjV).length === 0) {
      setFieldValue("serviceJobsObj", serviceJobsV);
    }

    // Initialize selected IDs based on existing data
    if (serviceJobsV.maintenance) {
      setSelectedMaintenanceIds(serviceJobsV.maintenance.map((job) => job._id));
    }
    if (serviceJobsV.housekeeping) {
      setSelectedHousekeepingIds(serviceJobsV.housekeeping.map((job) => job._id));
    }
    if (serviceJobsV.laundry) {
      setSelectedLaundryIds(serviceJobsV.laundry.map((job) => job._id));
    }
    if (serviceJobsV.luggage) {
      setSelectedLuggageIds(serviceJobsV.luggage.map((job) => job._id));
    }
  }, [dispatch, serviceJobsV, serviceJobsObjV, setFieldValue]);

  const handleMaintenanceChange = (option) => {
    const maintenanceJobs = serviceJobsObjV.maintenance || [];
    const isOptionSelected = maintenanceJobs.some((job) => job.slug === option.slug);

    const updatedMaintenanceJobs = isOptionSelected
      ? maintenanceJobs.filter((job) => job.slug !== option.slug)
      : [...maintenanceJobs, option];

    const selectedJobIds = updatedMaintenanceJobs.map((job) => job._id);
    setFieldValue("serviceJobsObj", { ...serviceJobsObjV, maintenance: updatedMaintenanceJobs });
    setSelectedMaintenanceIds(selectedJobIds);
  };

  const handleHouseKeepingChange = (option) => {
    const houseKeepingJobs = serviceJobsObjV.housekeeping || [];
    const isOptionSelected = houseKeepingJobs.some((job) => job.slug === option.slug);
    const updatedhouseKeepingJobs = isOptionSelected
      ? houseKeepingJobs.filter((job) => job.slug !== option.slug)
      : [...houseKeepingJobs, option];

    const selectedJobIds = updatedhouseKeepingJobs.map((job) => job._id);
    setFieldValue("serviceJobsObj", { ...serviceJobsObjV, housekeeping: updatedhouseKeepingJobs });
    setSelectedHousekeepingIds(selectedJobIds);
  };

  const handleLaundryChange = (option) => {
    const laundryJobs = serviceJobsObjV.laundry || [];
    const isOptionSelected = laundryJobs.some((job) => job.slug === option.slug);
    const updatedLaundryJobs = isOptionSelected
      ? laundryJobs.filter((job) => job.slug !== option.slug)
      : [...laundryJobs, option];

    const selectedJobIds = updatedLaundryJobs.map((job) => job._id);
    setFieldValue("serviceJobsObj", { ...serviceJobsObjV, laundry: updatedLaundryJobs });
    setSelectedLaundryIds(selectedJobIds);
  };

  const handleLuggageChange = (option) => {
    const luggageJobs = serviceJobsObjV.luggage || [];
    const isOptionSelected = luggageJobs.some((job) => job.slug === option.slug);
    const updatedLuggageJobs = isOptionSelected
      ? luggageJobs.filter((job) => job.slug !== option.slug)
      : [...luggageJobs, option];

    const selectedJobIds = updatedLuggageJobs.map((job) => job._id);
    setFieldValue("serviceJobsObj", { ...serviceJobsObjV, luggage: updatedLuggageJobs });
    setSelectedLuggageIds(selectedJobIds);
  };

  const selectedJobIds = [
    ...selectedMaintenanceIds,
    ...selectedHousekeepingIds,
    ...selectedLaundryIds,
    ...selectedLuggageIds,
  ];

  useEffect(() => {
    setFieldValue("serviceJobs", selectedJobIds);
  }, [
    selectedMaintenanceIds,
    selectedHousekeepingIds,
    selectedLaundryIds,
    selectedLuggageIds,
    setFieldValue,
  ]);

  // Ensure serviceJobsObjV and serviceJobs are consistent
  const detailListData = {
    maintenance: serviceJobsObjV.maintenance?.map((job) => job.title).join(", ") || "",
    housekeeping: serviceJobsObjV.housekeeping?.map((job) => job.title).join(", ") || "",
    laundry: serviceJobsObjV.laundry?.map((job) => job.title).join(", ") || "",
    luggage: serviceJobsObjV.luggage?.map((job) => job.title).join(", ") || "",
  };

  return (
    <MDBox>
      {detailPage && editPage ? (
        <>
          <DetailList data={detailListData} />
          {values?.slug && (
            <div style={{ marginBottom: 10 }}>
              <Divider
                sx={{ height: "1px", margin: "8px 0", borderWidth: "1px", backgroundImage: "none" }}
              />
              <CopytoClipboard text={`${url}/locations/${values?.slug}/companion`} />
            </div>
          )}
        </>
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDTypography variant="h6" gutterBottom>
                Maintenance:
              </MDTypography>
              <FormGroup>
                <Grid container spacing={1}>
                  {serviceJobs?.maintenance?.map((option, index) => (
                    <Grid item xs={6} sm={4} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              serviceJobsObjV.maintenance?.some((job) => job.slug === option.slug)
                            )}
                            onChange={() => handleMaintenanceChange(option)}
                          />
                        }
                        label={option.title}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <MDTypography variant="h6" gutterBottom>
                Housekeeping:
              </MDTypography>
              <FormGroup>
                <Grid container spacing={1}>
                  {serviceJobs?.housekeeping?.map((option, index) => (
                    <Grid item xs={6} sm={4} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              serviceJobsObjV.housekeeping?.some((job) => job.slug === option.slug)
                            )}
                            onChange={() => handleHouseKeepingChange(option)}
                          />
                        }
                        label={option.title}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <MDTypography variant="h6" gutterBottom>
                Laundry:
              </MDTypography>
              <FormGroup>
                <Grid container spacing={1}>
                  {serviceJobs?.laundry?.map((option, index) => (
                    <Grid item xs={6} sm={4} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              serviceJobsObjV.laundry?.some((job) => job.slug === option.slug)
                            )}
                            onChange={() => handleLaundryChange(option)}
                          />
                        }
                        label={option.title}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <MDTypography variant="h6" gutterBottom>
                Luggage:
              </MDTypography>
              <FormGroup>
                <Grid container spacing={1}>
                  {serviceJobs?.luggage?.map((option, index) => (
                    <Grid item xs={6} sm={4} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(
                              serviceJobsObjV.luggage?.some((job) => job.slug === option.slug)
                            )}
                            onChange={() => handleLuggageChange(option)}
                          />
                        }
                        label={option.title}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

GuestCompanion.defaultProps = {
  editPage: false,
  detailPage: true,
};

GuestCompanion.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default GuestCompanion;
