// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { clearFilters } from "store/actions/userActions";

// Custom styles for the SidenavItem
import MDAvatar from "components/MDAvatar";
import { useDispatch } from "react-redux";
import { item, itemContent, itemArrow } from "./styles/sidenavItem";

const SidenavItem = ({ color, name, active, nested, icon, children, isUser, open, ...rest }) => {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const dispatch = useDispatch();
  const handleOnClick = () => {
    if (active) {
      window.location.reload();
    } else {
      dispatch(clearFilters());
    }
  };

  return (
    <>
      <ListItem
        {...rest}
        onClick={handleOnClick}
        component="li"
        sx={(theme) => item(theme, { active, color, transparentSidenav, whiteSidenav, darkMode })}
      >
        <MDBox
          sx={(theme) =>
            itemContent(theme, {
              active,
              miniSidenav,
              name,
              open,
              nested,
              transparentSidenav,
              whiteSidenav,
              darkMode,
            })
          }
        >
          {isUser ? (
            <MDAvatar alt="User Avatar" size="sm" src={icon} />
          ) : (
            <Icon sx={{ pr: 4, pl: 1 }}>{icon}</Icon>
          )}
          <ListItemText
            sx={{
              marginLeft: isUser ? "8px" : "0", // Conditional margin-left
              whiteSpace: "normal", // Set to 'normal' to allow text to wrap
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            primary={name}
          />
          {children && (
            <Icon
              component="i"
              sx={(theme) =>
                itemArrow(theme, { open, miniSidenav, transparentSidenav, whiteSidenav, darkMode })
              }
            >
              expand_less
            </Icon>
          )}
        </MDBox>
      </ListItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit {...rest}>
          {children}
        </Collapse>
      )}
    </>
  );
};

// Setting default values for the props of SidenavItem
SidenavItem.defaultProps = {
  color: "info",
  active: false,
  nested: false,
  children: false,
  open: false,
  icon: "dashboard",
  isUser: false,
};

// Typechecking props for the SidenavItem
SidenavItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "primaryC",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  name: PropTypes.string.isRequired,
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  nested: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
  // icon: PropTypes.string,
  isUser: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default SidenavItem;
