import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "components/Tables/DataTable";

import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "store/actions/statsActions";

const OrdersDataTable = ({ locationId }) => {
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.stats.orders);
  const user = useSelector((state) => state.users.user);

  useEffect(() => {
    setTimeout(2000);
    if (locationId) {
      dispatch(getOrders(locationId));
    } else {
      dispatch(getOrders(""));
    }
  }, [locationId]);

  const [state, setState] = useState({
    columns: [
      { Header: "Title", accessor: "title", width: "30%" },
      { Header: "Email", accessor: "email", width: "20%" },
      { Header: "Date", accessor: "date", width: "10%" },
      { Header: "Type", accessor: "type", width: "10%" },
      { Header: "Source", accessor: "source", width: "13%" },
      { Header: "Price ($)", accessor: "price", width: "17%" },
    ],
    rows: [],
  });

  useEffect(() => {
    if (orders?.length > 0) {
      const transformedData = orders?.map((item) => ({
        title: item.title,
        email: item.email,
        date: item.date,
        type: item.type,
        source: item.source,
        price: item.total?.toFixed(2),
      }));
      setState({ ...state, rows: transformedData });
    } else {
      setState({ ...state, rows: [] });
    }
  }, [orders]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <MDBox pt={3} px={3}>
            <MDTypography variant="h6" fontWeight="medium">
              Orders
            </MDTypography>
          </MDBox>
          <MDBox py={1}>
            <DataTable
              key={user.role}
              table={state}
              entriesPerPage
              showTotalEntries
              isSorted
              noEndBorder
            />
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
};

// Setting default props
OrdersDataTable.defaultProps = {
  locationId: "",
};

// typechecking props for OrdersDataTable
OrdersDataTable.propTypes = {
  locationId: PropTypes.string,
};

export default OrdersDataTable;
