import React from "react";
import { Link, Text, Img } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";

const SocialLinks = () => (
  <Tailwind>
    <div className="flex flex-col  items-center justify-center">
      <Text className="text-center text-[17px] leading-[19.55px] font-bold text-black mb-4">
        Follow Us
      </Text>
      <div className="flex justify-center gap-[29px] space-x-4 px-10">
        <Link href="https://www.instagram.com/lokalee.app/" className="flex items-center">
          <Img
            src="https://email-assets.lokalee.app/email/emailImages/instagram.png"
            alt="Instagram"
            className="w-[34px] h-[32px]"
          />
        </Link>

        <Link
          href="https://www.linkedin.com/company/linkedin.com-lokalee/mycompany/"
          className="flex items-center"
        >
          <Img
            src="https://email-assets.lokalee.app/email/emailImages/linkedin.png"
            alt="LinkedIn"
            className="w-[34px] h-[32px]"
          />
        </Link>
        <Link href="https://www.facebook.com/lokaleeapp" className="flex items-center">
          <Img
            src="https://email-assets.lokalee.app/email/emailImages/facebook.png"
            alt="Facebook"
            className="w-[34px] h-[32px]"
          />
        </Link>
      </div>
    </div>
  </Tailwind>
);

export default SocialLinks;
