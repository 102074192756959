import checkout from "./form";

const {
  formField: {
    title,
    logo,
    option,
    filterBy,
    type,
    destinations,
    locations,
    tags,
    tickets,
    url,
    top,
    hidden,
    expandable,
    filters,
  },
} = checkout;

const initialValues = {
  // user page
  [title.name]: "",
  [logo.name]: "",
  [type.name]: "global",
  [destinations.name]: [],
  [locations.name]: [],
  [option.name]: "Recommendation",
  [filterBy.name]: "tags",
  [tags.name]: [],
  [tickets.name]: [],
  [url.name]: "",
  [top.name]: false,
  [hidden.name]: false,
  [expandable.name]: false,
  [filters.name]: [
    "category",
    "distance",
    "language",
    "cuisines",
    "price",
    "features",
    "opening hours",
    "duration",
  ],
};

export default initialValues;
