/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import { Grid } from "@mui/material";
import { Check, Clear } from "@mui/icons-material";
import { deleteTag, getOneTag } from "store/actions/tagActions";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "./CustomPagination";

const TagAndSubtagTables = ({
  currentType,
  handleChange,
  selectedTag,
  setSelectedTag,
  selectedTag2,
  setSelectedTag2,
}) => {
  const dispatch = useDispatch();

  const [tableKey, setTableKey] = useState(Date.now());

  const added = useSelector((state) => state.tags.added);
  const tagsCol = [
    {
      title: "Tag",
      field: "title",
      cellStyle: {
        width: 120,
        maxWidth: 120,
        whiteSpace: "normal",
        lineHeight: "normal",
      },
      render: (rowData) => (
        <div style={{ maxWidth: 120, wordWrap: "break-word" }}>
          <span>{rowData.title}</span>
        </div>
      ),
      defaultFilter: "",
    },

    {
      title: "Source",
      field: "source",
      editable: "never",
      cellStyle: {
        width: 90,
        maxWidth: 90,
      },
    },
    {
      title: "Top",
      field: "top",
      editable: "never",
      type: "boolean",
      cellStyle: {
        width: 30,
        maxWidth: 30,
      },
      render: (rowData) =>
        rowData.top ? (
          <div>
            <Check
              style={{
                color: "green",
                maxWidth: "30px",
              }}
            />
          </div>
        ) : (
          <div>
            <Clear style={{ color: "red", maxWidth: "30px" }} />
          </div>
        ),
    },
  ];

  const subTagsCol = [
    {
      title: "Subtag",
      field: "title",
      cellStyle: {
        width: 120,
        maxWidth: 120,
        whiteSpace: "normal",
        lineHeight: "normal",
      },
      render: (rowData) => (
        <div style={{ maxWidth: 120, wordWrap: "break-word" }}>
          <span>{rowData.title}</span>
        </div>
      ),
      defaultFilter: "",
    },

    {
      title: "Source",
      field: "source",
      editable: "never",
      cellStyle: {
        width: 90,
        maxWidth: 90,
      },
    },
    {
      title: "Top",
      field: "top",
      editable: "never",
      type: "boolean",
      cellStyle: {
        width: 30,
        maxWidth: 30,
      },
      render: (rowData) =>
        rowData.top ? (
          <div>
            <Check
              style={{
                color: "green",
                maxWidth: "30px",
              }}
            />
          </div>
        ) : (
          <div>
            <Clear style={{ color: "red", maxWidth: "30px" }} />
          </div>
        ),
    },
  ];

  // Handle row click on main table
  const onTagRowClick = (event, rowData) => {
    setSelectedTag(rowData);
    setSelectedTag2(null);
  };

  const onTagRowClick2 = (event, rowData) => {
    setSelectedTag2(rowData);
  };

  // Trigger table update when `added` changes
  useEffect(() => {
    setTableKey(Date.now());
  }, [currentType, added]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <MaterialTable
          key={tableKey}
          title="Tags"
          columns={tagsCol}
          data={(query) =>
            new Promise((resolve) => {
              let url;
              url = `${process.env.REACT_APP_API}/tags?`;
              if (currentType === "RECOMMENDATIONS" || currentType === "TICKETS")
                url += `type=${currentType}`;
              url += `&limit=${query.pageSize}`;
              url += `&page=${query.page + 1}`;
              if (query.search.length > 0) url += `&title=${encodeURIComponent(query.search)}`;
              if (query.orderBy) {
                url += `&sort=${query.orderBy?.field}`;
                url += `&dir=${query.orderDirection}`;
              } else {
                url += "&sort=createdAt";
                url += "&dir=desc";
              }
              fetch(url)
                .then((response) => response.json())
                .then((result) => {
                  resolve({
                    data: result.data,
                    page: result.page - 1,
                    totalCount: result?.total,
                  });
                });
              // eslint-disable-next-line no-console
            }).catch((error) => console.log(error.message))
          }
          onRowClick={onTagRowClick}
          components={{
            Pagination: CustomPagination,
          }}
          options={{
            padding: "dense",
            headerStyle: {
              fontSize: "12px",
            },
            cellStyle: {
              fontSize: "12px",
            },
            actionsColumnIndex: -1,
            pageSize: 10,
            rowStyle: (rowData) => ({
              backgroundColor: selectedTag && selectedTag._id === rowData._id ? "#EEE" : "#FFF",
            }),
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Tag",
              onClick: (event, rowData) => {
                dispatch(getOneTag(rowData?._id));
                handleChange(event, rowData);
              },
            },
          ]}
          editable={{
            onRowDelete: (oldData) => dispatch(deleteTag(oldData._id)),
          }}
        />
      </Grid>
      <Grid item xs={4}>
        {selectedTag && (
          <MaterialTable
            key={selectedTag?._id}
            onRowClick={onTagRowClick2}
            title={
              <div
                style={{
                  wordWrap: "break-word",
                  maxWidth: "300px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >{`Subtags for "${selectedTag.title}"`}</div>
            }
            columns={subTagsCol}
            components={{
              Pagination: CustomPagination,
            }}
            editable={{
              onRowDelete: (oldData) => dispatch(deleteTag(oldData._id)),
            }}
            data={(query) =>
              new Promise((resolve) => {
                let url;
                url = `${process.env.REACT_APP_API}/tags?`;
                url += `parentTagIds=${selectedTag?._id}&`;
                url += `limit=${query.pageSize}`;
                url += `&page=${query.page + 1}`;
                if (query.search.length > 0) url += `&title=${encodeURIComponent(query.search)}`;
                if (query.orderBy) {
                  url += `&sort=${query.orderBy?.field}`;
                  url += `&dir=${query.orderDirection}`;
                } else {
                  url += "&sort=createdAt";
                  url += "&dir=desc";
                }
                fetch(url)
                  .then((response) => response.json())
                  .then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page - 1,
                      totalCount: result?.total,
                    });
                  });
                // eslint-disable-next-line no-console
              }).catch((error) => console.log(error.message))
            }
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Tag",
                onClick: (event, rowData) => {
                  dispatch(getOneTag(rowData?._id));
                  handleChange(event, rowData);
                  // setRowSelected(rowData);
                  // setDialogOpen(true);
                  // onRowSelect(rowData);
                },
              },
            ]}
            options={{
              padding: "dense",
              headerStyle: {
                fontSize: "12px",
              },
              cellStyle: {
                fontSize: "12px",
              },
              actionsColumnIndex: -1,
              pageSize: 10,
              rowStyle: (rowData) => ({
                backgroundColor: selectedTag2 && selectedTag2._id === rowData._id ? "#EEE" : "#FFF",
              }),
            }}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        {selectedTag2 && (
          <MaterialTable
            key={selectedTag2?._id}
            // title={`Subtags for "${selectedTag.title}"`}
            title={
              <div
                style={{
                  wordWrap: "break-word",
                  maxWidth: "300px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >{`Subtags for "${selectedTag2.title}"`}</div>
            }
            columns={subTagsCol}
            components={{
              Pagination: CustomPagination,
            }}
            editable={{
              onRowDelete: (oldData) => dispatch(deleteTag(oldData._id)),
            }}
            data={(query) =>
              new Promise((resolve) => {
                let url;
                url = `${process.env.REACT_APP_API}/tags?`;
                url += `parentTagIds=${selectedTag2?._id}&`;
                url += `limit=${query.pageSize}`;
                url += `&page=${query.page + 1}`;
                if (query.search.length > 0) url += `&title=${encodeURIComponent(query.search)}`;
                if (query.orderBy) {
                  url += `&sort=${query.orderBy?.field}`;
                  url += `&dir=${query.orderDirection}`;
                } else {
                  url += "&sort=createdAt";
                  url += "&dir=desc";
                }
                fetch(url)
                  .then((response) => response.json())
                  .then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page - 1,
                      totalCount: result?.total,
                    });
                  });
                // eslint-disable-next-line no-console
              }).catch((error) => console.log(error.message))
            }
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Tag",
                onClick: (event, rowData) => {
                  dispatch(getOneTag(rowData?._id));
                  handleChange(event, rowData);
                  // setRowSelected(rowData);
                  // setDialogOpen(true);
                  // onRowSelect(rowData);
                },
              },
            ]}
            options={{
              padding: "dense",
              headerStyle: {
                fontSize: "12px",
              },
              cellStyle: {
                fontSize: "12px",
              },
              actionsColumnIndex: -1,
              pageSize: 10,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

TagAndSubtagTables.defaultProps = {
  currentType: "",
  // setDialogOpen: () => {},
  handleChange: () => {},
  // onRowSelect: () => {},
  selectedTag: {},
  setSelectedTag: () => {},
  selectedTag2: {},
  setSelectedTag2: () => {},
};

TagAndSubtagTables.propTypes = {
  currentType: PropTypes.string,
  // setDialogOpen: PropTypes.func,
  // onRowSelect: PropTypes.func,
  handleChange: PropTypes.func,
  selectedTag: PropTypes.instanceOf(Object),
  setSelectedTag: PropTypes.func,
  selectedTag2: PropTypes.instanceOf(Object),
  setSelectedTag2: PropTypes.func,
};

export default TagAndSubtagTables;
