/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { Avatar, Divider } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import DetailList from "components/DetailList";
import { useEffect, useState } from "react";
import { areAllValuesEmpty } from "components/helper";
import { useSelector } from "react-redux";

const Review = ({ formData, editPage, selectedPrimaryDestination }) => {
  const { values } = formData;
  const [recommendationInfoData, setRecommendationInfoData] = useState({});
  const [addressInfoData, setAddressInfoData] = useState({});
  const [dateAndTimeData, setDateAndTimeData] = useState({});
  const [addInfoData, setAddInfoData] = useState({});

  const [fields, setFields] = useState([]);
  const locations = useSelector((state) => state.locations.locations);
  const locationPartnerId = useSelector((state) => state.locations.location);
  const locationPartner = locations
    .map((loc) => (loc._id === locationPartnerId ? loc : null))
    .find((loc) => loc !== null);

  useEffect(() => {
    if (values) {
      setRecommendationInfoData({
        title: values?.title,
        subHeader: values?.subHeader,
        description: values?.description,
        tags: values?.tags,
        cuisines: values?.cuisines,
        features: values?.features,
      });
      setAddressInfoData({
        primaryDestination: selectedPrimaryDestination?.label,
        destinations:
          values?.destinations && values?.destinations.length > 0
            ? values?.destinations.map((option) => option.label).join(", ")
            : [],
        location: values?.location || locationPartner?.name || "",
        address: values?.address,
        country: values?.country,
        city: values?.city,
        lat: values?.lat,
        lng: values?.lng,
      });
      setAddInfoData({
        email: values?.email,
        phone: values?.phone,
        webAddresses: values?.webAddresses,
        callToAction: values?.callToAction,
        callToAction2: values?.callToAction2,
        price: values?.price,
        sponsored: values?.sponsored,
        currated: values?.currated,
        highlight: values?.highlight,
        special: values?.special,
        experienceX: values?.experienceX,
        exclusive: values?.exclusive,
        insider: values?.insider,
        exclusiveLocation: values?.exclusiveLocation,
        exclusivePartner: values?.exclusivePartner,
        top: values?.top,
        traveltype: values?.traveltype,
        images: values?.images,
        badges: values?.badges?.label,
      });
      setDateAndTimeData({
        openHours: values?.openHours,
      });
    }
  }, [values]);

  useEffect(() => {
    setFields([
      { id: 0, title: "Recommendation info", data: recommendationInfoData },
      { id: 1, title: "Address", data: addressInfoData },
      { id: 4, title: "Additional info", data: addInfoData },
      { id: 5, title: "Date And Time Info", data: dateAndTimeData },
    ]);
  }, [recommendationInfoData, addressInfoData, addInfoData, dateAndTimeData]);

  const BigAvatar = styled(Avatar)`
    width: 74px;
    height: 74px;
  `;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {!editPage ? (
            <Grid item xs={12} sm={6}>
              {values?.logo ? (
                <MDAvatar
                  src={URL.createObjectURL(values?.logo)}
                  alt="profile-image"
                  size="xl"
                  sx={{ "& img": { height: "100%" } }}
                />
              ) : (
                <BigAvatar
                  alt="Change your profile photo"
                  src={URL.revokeObjectURL(values?.logo)}
                  imgProps={{
                    style: {
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "cover",
                    },
                  }}
                />
              )}
            </Grid>
          ) : (
            ""
          )}
          {fields?.map((field, i) => (
            <Grid
              item
              key={field.id}
              xs={12}
              sx={{
                display: areAllValuesEmpty(field.data) ? "none" : "flex",
                flexDirection: "column",
              }}
            >
              <DetailList data={field.data} title={field?.title} />
              {i + 1 !== fields?.length && (
                <div>
                  <Divider sx={{ height: "1px" }} />
                </div>
              )}{" "}
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// Setting default props for the Review
Review.defaultProps = {
  editPage: false,
  selectedPrimaryDestination: {},
};

// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  selectedPrimaryDestination: PropTypes.instanceOf(Object),
};

export default Review;
