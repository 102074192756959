/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { EditorState, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useHandleError } from "components/helper";

import TranslateIcon from "@mui/icons-material/Translate";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";
import ViewEditActions from "components/DetailList/ViewEditActions";
import DynamicTranslate from "components/DynamicTranslate";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import { clearErrors, setLoading } from "store/actions/locationAction";
import setAlert from "store/actions/alertActions";

// Schemas
import {
  getOneDestination,
  updateDestination,
  updateDestinationTranslations,
} from "store/actions/destinationsActions";
import validations from "./schemas/validations";
import form from "../new-destination/schemas/form";
import initialValues from "../new-destination/schemas/initialValues";
import DestinationDetail from "./components/DestinationDetail";

const EditDestination = () => {
  const { formId, formField } = form;
  const { destination_id } = useParams();

  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const destination = useSelector((state) => state.destinations.destination);
  const error = useSelector((state) => state.destinations.error);
  const added = useSelector((state) => state.destinations.added);
  const user = useSelector((state) => state.users.user);

  // state
  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [initialState, setInitialState] = useState(initialValues);
  const [image, _setImage] = useState(destination?.logo);
  const [tabValue, setTabValue] = useState(0);
  const [destinationDetailPage, setDestinationDetailPage] = useState(true);
  const [selectedParent, setSelectedParent] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState({});

  const [addressDetailPage, setAddressDetailPage] = useState(true);
  const [translateItems, setTranslateItems] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState({});

  const detailTabs = [
    { id: 1, title: "LOCATION DETAILS", iconComponent: AssignmentIcon },
    { id: 2, title: "TRANSLATE", iconComponent: TranslateIcon },
  ];

  // useEffect

  useEffect(() => {
    if (destination_id) {
      dispatch(getOneDestination(destination_id));
    }
  }, [destination_id, added]);

  useEffect(() => {
    if (destination?.parentId?.destinationName) {
      setSelectedParent(destination?.parentId);
    }
  }, [destination?.parentId?.destinationName]);

  useEffect(() => {
    setSelectedCurrency(destination?.defaultCurrencyCode);
    setSelectedTimeZone(destination?.timeZone);
  }, [destination?.timeZone, destination?.defaultCurrencyCode]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (destination?.logo) {
      _setImage(destination?.logo);
    }
  }, [destination?.logo]);

  useEffect(() => {
    if (
      user?.role !== "admin" &&
      user?.role !== "curator" &&
      destination?.partner?._id !== user._id
    ) {
      <Navigate to="/" />;
    }

    if (destination) {
      const arTranslation = destination?.translations?.ar;
      const arr = [
        {
          id: 1,
          name: "destinationName",
          title: "Destination Name",
          value: arTranslation?.destinationName || "",
          default: "",
          style: { fontSize: "15px" },
          label: "Destination Name",
        },
      ];
      setTranslateItems(arr);
    }
  }, [destination]);

  // We cant use the destination as is because of address picker and map picker
  useEffect(() => {
    if (destination) {
      const manipulated = {};
      manipulated.destinationName = destination?.destinationName
        ? destination?.destinationName
        : "";
      manipulated.destinationType = destination?.destinationType
        ? destination?.destinationType
        : "REGION";
      manipulated.parentId = destination?.parentId ? destination?.parentId : "";

      manipulated.top = destination?.top ? destination?.top : false;
      manipulated.highlight = destination?.highlight ? destination?.highlight : false;
      manipulated.logo = destination?.logo ? destination?.logo : "";
      manipulated.address = destination?.geoLocationData?.address;
      manipulated.city = destination?.geoLocationData?.city;
      manipulated.region = destination.geoLocationData?.region
        ? destination.geoLocationData?.region
        : "";
      manipulated.country = destination.geoLocationData?.country
        ? destination.geoLocationData?.country
        : "";
      manipulated.lat = destination.geoLocation?.coordinates[1]
        ? destination.geoLocation?.coordinates[1]
        : "";
      manipulated.lng = destination.geoLocation?.coordinates[0]
        ? destination.geoLocation?.coordinates[0]
        : "";
      manipulated.defaultCurrencyCode = destination?.defaultCurrencyCode
        ? destination?.defaultCurrencyCode
        : "USD";
      manipulated.iataCode = destination?.iataCode ? destination?.iataCode : "";

      manipulated.timeZone = destination?.timeZone ? destination?.timeZone : "";
      manipulated.country_code = destination?.country_code ? destination?.country_code : "";
      manipulated.countryCode = destination?.countryCode ? destination?.countryCode : "";

      manipulated._id = destination?._id ? destination?._id : "";

      setInitialState(manipulated);
    }
  }, [destination]);
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitForm = async (values) => {
    // const updatedValues = { ...values };
    const {
      destinationName = "",
      destinationType = "",
      parentId = "",
      top = false,
      highlight = false,
      address = "",
      city = "",
      region = "",
      country = "",
      lat = "",
      lng = "",
      defaultCurrencyCode = "",
      country_code = "",
      iataCode = "",
      timeZone = "",
      countryCode = "",
    } = values;

    const baseValues = {
      _id: values?._id,
      destinationName,
      destinationType,
      address,
      country,
      city,
      lat,
      lng,
    };
    let additionalValues = {};
    if (!destinationDetailPage) {
      additionalValues = {
        parentId: parentId?.value || parentId,
        highlight,
        top,
      };
    } else if (!addressDetailPage) {
      additionalValues = {
        region,
        defaultCurrencyCode,
        country_code,
        iataCode,
        timeZone,
        countryCode,
      };
    }
    const updatedValues = { ...baseValues, ...additionalValues };
    await sleep(1000);
    dispatch(setLoading());
    // eslint-disable-next-line no-alert
    dispatch(
      updateDestination(
        updatedValues,
        values?.logo !== destination?.logo ? values?.logo : undefined
      )
    );
    setDestinationDetailPage(true);
    setAddressDetailPage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleParentChange = (e, value) => {
    if (value && value?._id && destination?.destinationType !== "COUNTRY") {
      setSelectedParent(value);
    }
  };

  const handleCurrencyChange = (val) => {
    if (val) {
      setSelectedCurrency(val);
    }
  };
  const handleTimeZoneChange = (val) => {
    if (val) {
      setSelectedTimeZone(val);
    }
  };

  const handleRadioGroupChange = () => {
    if (destination?.destinationType === "COUNTRY") {
      setSelectedParent(null);
    }
  };

  const handleSubmitTranslate = (values) => {
    const { name, disclaimerText } = values;
    if (disclaimerText?.length > 0 && typeof disclaimerText === "string") {
      const parsedDisclaimerText = JSON.parse(disclaimerText);
      dispatch(updateDestinationTranslations({ name, parsedDisclaimerText }, destination?._id));
    } else {
      dispatch(updateDestinationTranslations(values, destination?._id));
    }
  };

  function getStepContent(type, formData) {
    let detailPage;
    let setDetailPage;

    switch (type) {
      case "destinationInfo":
        detailPage = destinationDetailPage;
        setDetailPage = setDestinationDetailPage;
        break;
      default:
        detailPage = addressDetailPage;
        setDetailPage = setAddressDetailPage;
        break;
    }

    const handleEdit = () => {
      formData.resetForm();
      if (type === "destinationInfo") {
        setDestinationDetailPage(false);
        setAddressDetailPage(true);
      }
      if (type === "address") {
        setDestinationDetailPage(true);
        setAddressDetailPage(false);
      }

      setImgDetailPage(true);
    };

    return (
      <DestinationDetail
        locationId={destination?._id}
        type={type}
        formData={formData}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        handleEdit={handleEdit}
        handleParentChange={handleParentChange}
        handleRadioGroupChange={handleRadioGroupChange}
        selectedParent={selectedParent}
        handleCurrencyChange={handleCurrencyChange}
        selectedCurrency={selectedCurrency}
        selectedTimeZone={selectedTimeZone}
        handleTimeZoneChange={handleTimeZoneChange}
      />
    );
  }
  const handleIconChange = (value) => {
    if (!value || !value.name) {
      setSelectedIcon({});
    } else {
      setSelectedIcon(value);
    }
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOneDestination(destination_id));
    }
  };

  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return (
          <DynamicTranslate
            items={translateItems}
            handleSubmit={handleSubmitTranslate}
            id={destination?._id}
          />
        );

      default:
        return null;
    }
  };

  const handleNavigation = () => {
    let url = null;
    url = `/${user.role}/destinations?`;
    navigate(url);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={!addressDetailPage ? validations[1] : validations[0]}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                // setFormChange(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <DynamicHeader
                      image={image}
                      imgDetailPage={imgDetailPage}
                      user={destination}
                      detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      imageType="logo"
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      {tabValue === 0 ? (
                        <ViewEditActions
                          detailPage={imgDetailPage}
                          stateName="destinations"
                          handleEdit={() => {
                            setImgDetailPage(false);
                            setDestinationDetailPage(true);
                            setAddressDetailPage(true);
                          }}
                          handleClose={() => {
                            _setImage(destination?.logo);
                            setImgDetailPage(true);
                          }}
                          handleSave={() => {
                            submitForm(values);
                            setImgDetailPage(true);
                          }}
                        />
                      ) : null}
                    </DynamicHeader>
                    {tabValue === 0 ? (
                      <>
                        {["destinationInfo", "address"].map((item, index) => (
                          <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                            <MDBox p={2}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item xs={12}>
                                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    {getStepContent(
                                      item,
                                      {
                                        values,
                                        touched,
                                        formField,
                                        errors,
                                        setFieldValue,
                                        resetForm,
                                      },
                                      selectedIcon,
                                      handleIconChange
                                    )}
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </Card>
                        ))}
                      </>
                    ) : (
                      handleDetailData()
                    )}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};
// Setting default props
DestinationDetail.defaultProps = {
  detailPage: true,
  setDetailPage: () => {},
  type: "",
  selectedParent: {},
  handleParentChange: () => {},
  handleRadioGroupChange: () => {},
  isParentPicker: false,
  // editorState: {},
  // setEditorState: () => {},
};

// typechecking props for AttractionDetail
DestinationDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  isParentPicker: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  type: PropTypes.string,
  selectedParent: PropTypes.instanceOf(Object),
  handleParentChange: PropTypes.func,
  handleRadioGroupChange: PropTypes.func,
};

export default EditDestination;
