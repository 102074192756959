/* eslint-disable no-underscore-dangle */

// React
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
// import { useSearchParams, useNavigate } from "react-router-dom";

// @mui material
import { Autocomplete, Grid } from "@mui/material";

// Actions
import {
  getGlobalMenus,
  getDestinationMenus,
  getLocationMenus,
  setLoading,
  updateMenuType,
} from "store/actions/menuActions";
import { getLocations } from "store/actions/locationAction";

// Components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDBox from "components/MDBox";
import DynamicTabs from "components/DynamicTabs";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Loader from "components/Loader";
import DestinationsPicker from "components/AutoComplete/DestinationsPicker";
import GlobalMenusList from "./components/MenusList";

const AllMenus = () => {
  const dispatch = useDispatch();

  const locations = useSelector((state) => state.locations.locations);
  const menus = useSelector((state) => state.menus.destinationsMenus);
  const loading = useSelector((state) => state.menus.loading);
  const cityLoading = useSelector((state) => state.cities.loading);
  const globalMenus = useSelector((state) => state.menus.globalMenus);
  const locationMenus = useSelector((state) => state.menus.locationMenus);
  const menuType = useSelector((state) => state.menus.menuType);
  const added = useSelector((state) => state.menus.added);

  const [tabValue, setTabValue] = useState(0);
  const [menusList, setMenusList] = useState([]);
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const detailTabs = [
    { id: 1, title: "Global Menu" },
    { id: 2, title: "Destinations Menu" },
    { id: 3, title: "Location Menu" },
  ];

  const data = [
    {
      title: "Global Menu",
      columns: ["Title", "Order", "Option", "Has Items", "Expandable"],
      screen: "GlobalMenusList",
    },
    {
      title: "Destinations Menu",
      columns: ["Title", "Order", "Option", "Has Items", "Expandable"],
      screen: "DestinationsMeusList",
    },
    {
      title: "Location Menu",
      columns: ["Title", "Order", "Option", "Has Items", "Expandable"],
      screen: "LocationMenusList",
    },
  ];

  useEffect(() => {
    if (menuType?.type === "destinations") {
      setTabValue(1);
      setSelectedDestinations(menuType.destinations);
      if (menuType?.destinations?.length > 0) {
        const titles = menuType?.destinations.map((obj) => obj.value);
        const joinedString = titles.join(",");
        dispatch(getDestinationMenus(joinedString));
      }
    } else if (menuType?.type === "location") {
      setTabValue(2);
      setSelectedLocations(menuType?.locations);
      if (menuType?.locations?.length > 0) {
        const titles = menuType?.locations.map((obj) => obj._id);
        const joinedString = titles.join(",");
        dispatch(getLocationMenus(joinedString));
      }
    } else {
      dispatch(getGlobalMenus());
      setTabValue(0);
    }
  }, [menuType?.type, added]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    // setUpdatedData(data[newValue]);// Update the URL with a query parameter indicating the active tab
    if (newValue === 0) {
      dispatch(setLoading());
      dispatch(getGlobalMenus());
      setSelectedLocations([]);
      setSelectedDestinations([]);
      dispatch(updateMenuType({ type: "global", locations: [], destinations: [] }));
    }
    if (newValue === 1) {
      setSelectedLocations([]);
      dispatch(updateMenuType({ ...menuType, type: "destinations", locations: [] }));
    }
    if (newValue === 2) {
      dispatch(getLocations());
      setSelectedDestinations([]);
      dispatch(updateMenuType({ ...menuType, type: "location", destinations: [] }));
    }
  };

  const handleDestinationsChange = (val) => {
    if (val) {
      dispatch(setLoading());
      const titles = val.map((obj) => obj.value);
      const joinedString = titles.join(",");
      dispatch(getDestinationMenus(joinedString));
      setSelectedDestinations(val);
      // setSelectedLocations({});
      dispatch(updateMenuType({ ...menuType, destinations: val }));
    }
  };

  const handleLocationChange = (val) => {
    if (val) {
      dispatch(setLoading());
      const titles = val.map((obj) => obj._id);
      const joinedString = titles.join(",");
      dispatch(getLocationMenus(joinedString));
      setSelectedLocations(val);
      // setSelectedDestinations({});
      dispatch(updateMenuType({ ...menuType, locations: val }));
    }
  };

  useEffect(() => {
    if (globalMenus?.length > 0 && tabValue === 0) {
      setMenusList(globalMenus);
      // navigate("");
    } else if (
      selectedDestinations?.length > 0 &&
      selectedDestinations[0]?.value?.length > 0 &&
      tabValue === 1
    ) {
      if (menus?.length > 0) {
        setMenusList(menus);
      } else {
        setMenusList([]);
      }
    } else if (
      selectedLocations?.length > 0 &&
      selectedLocations[0]?.id?.length > 0 &&
      tabValue === 2
    ) {
      if (locationMenus?.length > 0) {
        setMenusList(locationMenus);
      } else {
        setMenusList([]);
      }
    } else {
      setMenusList([]);
    }
  }, [globalMenus, menus, locationMenus, selectedDestinations, selectedLocations, tabValue]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} md="auto">
          <MDBox>
            <DynamicTabs
              detailTabs={detailTabs}
              tabValue={tabValue}
              handleSetTabValue={handleSetTabValue}
            />
          </MDBox>
          {tabValue === 1 && (
            <MDBox
              style={{
                marginTop: "16px",
              }}
            >
              <MDTypography variant="h6" color="text" fontWeight="regular">
                Select a Destination
              </MDTypography>
              <MDBox
                style={{
                  marginTop: "8px",
                  backgroundColor: "transparent",
                  borderRadius: "8px",
                }}
              >
                <Grid item xs={12} sx={{ mt: "16px" }}>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <DestinationsPicker
                      setFieldValue={() => {}}
                      handleChange={handleDestinationsChange}
                      destinations={{ name: "destination", Label: "Destination" }}
                      destinationsV={selectedDestinations}
                    />
                  </div>
                </Grid>
              </MDBox>
            </MDBox>
          )}
          {tabValue === 2 && (
            <MDBox
              style={{
                marginTop: "16px",
              }}
            >
              <MDTypography variant="h6" color="text" fontWeight="regular">
                Select a Location
              </MDTypography>
              <MDBox
                style={{
                  marginTop: "8px",
                  backgroundColor: "white",
                  borderRadius: "8px",
                }}
              >
                <Autocomplete
                  multiple
                  options={locations}
                  value={selectedLocations}
                  onChange={(event, value) => {
                    handleLocationChange(value);
                  }}
                  loading={cityLoading}
                  getOptionLabel={(option) => option?.name || ""}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => <MDInput {...params} label="Locations" />}
                />
              </MDBox>
            </MDBox>
          )}
        </Grid>

        <Grid item xs={12}>
          {loading && menusList?.length === 0 && <Loader />}
          {((!loading && tabValue === 0) ||
            (!loading &&
              tabValue === 1 &&
              selectedDestinations?.length > 0 &&
              selectedDestinations[0]?.value?.length > 0) ||
            (!loading &&
              tabValue === 2 &&
              selectedLocations?.length > 0 &&
              selectedLocations[0]?.id?.length > 0)) && (
            <GlobalMenusList
              list={data[tabValue]}
              data={menusList}
              loading={loading}
              tabValue={tabValue}
              selectedDestinations={selectedDestinations}
              selectedLocations={selectedLocations}
            />
          )}
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default AllMenus;
