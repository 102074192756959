/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-prop-types */
import React, { useState } from "react";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";
import { dateFormatWithDash } from "components/helper";

const AvailabilitySchedulePanel = ({
  setFieldValue,
  availabilities,
  availabilitiesAdded,
  scheduleOrOption,
}) => {
  const [listEdited, setListEdited] = useState([]);
  const deleteAvailability = (i) => {
    const arr = availabilities;
    arr.splice(i, 1);
    setListEdited(arr);
    setFieldValue("availabilities.bookableItems", arr);
    // setFieldValue("itinerary.itineraryItems", arr);
  };

  return (
    <Box
      sx={{
        marginTop: "30px",
        marginBottom: "0px",
      }}
    >
      {availabilities?.length > 0 &&
        availabilities?.map((row, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container alignItems="center">
                <Grid item xs="auto">
                  {row?.productOption?.title && (
                    <Typography
                      variant="body1"
                      style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                    >
                      {row?.productOption?.title}
                    </Typography>
                  )}

                  {scheduleOrOption === "schedule" && (
                    <>
                      {row?.seasons?.map((season) => (
                        <>
                          {season?.startDate && !season?.endDate && (
                            <Typography
                              variant="body1"
                              style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                            >
                              {dateFormatWithDash(season?.startDate)}
                            </Typography>
                          )}
                          {season?.startDate && season?.endDate && (
                            <Typography
                              variant="body1"
                              style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                            >
                              {dateFormatWithDash(season?.startDate)} {" - "}{" "}
                              {dateFormatWithDash(season?.endDate)}
                            </Typography>
                          )}
                        </>
                      ))}
                    </>
                  )}
                </Grid>

                <Grid item>
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    edge="end"
                    onClick={() => {
                      deleteAvailability(index);
                      // setFieldValue("availabilities", listEdited);
                    }}
                  >
                    <DeleteIcon sx={{ color: "grey" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="span" variant="body2">
                <div>
                  {scheduleOrOption === "option" && (
                    <>
                      {row?.startDate && !row?.endDate && (
                        <Typography
                          variant="body1"
                          style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                        >
                          {row?.startDate}
                        </Typography>
                      )}
                      {row?.startDate && row?.endDate && (
                        <Typography
                          variant="body1"
                          style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                        >
                          {row?.startDate} {"    "}- {"    "} {row?.endDate}
                        </Typography>
                      )}
                    </>
                  )}

                  {row?.seasons?.map((season) =>
                    season.pricingRecords?.map((pricingRecord) => (
                      <div key={pricingRecord.id}>
                        {pricingRecord?.pricingRecords?.length > 0
                          ? pricingRecord.pricingRecords.map((innerPricingRecord) => (
                              <div key={innerPricingRecord.id}>
                                <span style={{ display: "block" }}>
                                  {innerPricingRecord.daysOfWeek.join(", ")}
                                </span>
                                {innerPricingRecord.pricingDetails.map((pricingDetail) => (
                                  <span key={pricingDetail.id} style={{ display: "block" }}>
                                    {pricingDetail.ageBand}:
                                    {pricingDetail?.price?.original?.recommendedRetailPrice}
                                  </span>
                                ))}
                                {innerPricingRecord.timedEntries.map((timeEntry) => (
                                  <span key={timeEntry._id} style={{ display: "block" }}>
                                    {timeEntry.startTime}
                                  </span>
                                ))}
                              </div>
                            ))
                          : pricingRecord?.daysOfWeek?.length > 0 && (
                              <span style={{ display: "block" }}>
                                {pricingRecord.daysOfWeek.join(", ")}
                              </span>
                            )}
                      </div>
                    ))
                  )}

                  {/* {row?.timedEntries?.length > 0 && (
                    <span style={{ display: "block" }}>{row.timedEntries.join(", ")}</span>
                  )}
                  {row?.prices?.length > 0 &&
                    row?.prices?.map((eachPrice) => (
                      <span key={index} style={{ display: "block" }}>
                        <span>
                          {eachPrice?.ageBand}: {eachPrice?.retailPrice}
                        </span>
                      </span>
                    ))} */}

                  {row?.seasons?.map((season) =>
                    season?.pricingRecords?.map((pricingRecord) => (
                      <div key={pricingRecord.id}>
                        {pricingRecord?.timedEntries?.length > 0 && (
                          <span style={{ display: "block" }}>
                            {pricingRecord.timedEntries.map((entry) => entry.startTime).join(", ")}
                          </span>
                        )}{" "}
                        {pricingRecord?.pricingDetails?.map((pricingDetail) => (
                          <span key={pricingDetail.id} style={{ display: "block" }}>
                            <span>
                              {pricingDetail.ageBand}:{" "}
                              {pricingDetail?.price?.original?.recommendedRetailPrice}
                            </span>
                            {/* <span></span> */}
                          </span>
                        ))}
                      </div>
                    ))
                  )}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}

      {availabilitiesAdded?.length > 0 &&
        availabilitiesAdded?.map((row, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container alignItems="center">
                <Grid item xs="auto">
                  {row?.productOption?.title && (
                    <Typography
                      variant="body1"
                      style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                    >
                      {row?.productOption?.title}
                    </Typography>
                  )}
                  {scheduleOrOption === "schedule" && (
                    <>
                      {row?.startDate && !row?.endDate && (
                        <Typography
                          variant="body1"
                          style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                        >
                          {row?.startDate}
                        </Typography>
                      )}
                      {row?.startDate && row?.endDate && (
                        <Typography
                          variant="body1"
                          style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                        >
                          {row?.startDate} {"    "}- {"    "} {row?.endDate}
                        </Typography>
                      )}
                    </>
                  )}
                </Grid>

                <Grid item>
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    edge="end"
                    onClick={() => {
                      deleteAvailability(index);
                      setFieldValue("availabilitiesAdded", listEdited);
                    }}
                  >
                    <DeleteIcon sx={{ color: "grey" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="span" variant="body2">
                <div>
                  {scheduleOrOption === "option" && (
                    <>
                      {row?.startDate && !row?.endDate && (
                        <Typography
                          variant="body1"
                          style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                        >
                          {row?.startDate}
                        </Typography>
                      )}
                      {row?.startDate && row?.endDate && (
                        <Typography
                          variant="body1"
                          style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                        >
                          {row?.startDate} {"    "}- {"    "} {row?.endDate}
                        </Typography>
                      )}
                    </>
                  )}
                  {row?.daysOfWeek?.length > 0 && (
                    <span style={{ display: "block" }}>{row.daysOfWeek.join(", ")}</span>
                  )}
                  {row?.timedEntries?.length > 0 && (
                    <span style={{ display: "block" }}>{row.timedEntries.join(", ")}</span>
                  )}
                  {row?.prices?.length > 0 &&
                    row?.prices.map((eachPrice) => (
                      <span key={index} style={{ display: "block" }}>
                        <span>
                          {eachPrice?.ageBand}: {eachPrice?.retailPrice}
                        </span>
                      </span>
                    ))}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
};
AvailabilitySchedulePanel.defaultProps = {
  availabilities: [],
  availabilitiesAdded: [],
  scheduleOrOption: "",
};

AvailabilitySchedulePanel.propTypes = {
  availabilities: PropTypes.instanceOf(Array),
  availabilitiesAdded: PropTypes.instanceOf(Array),
  setFieldValue: PropTypes.func.isRequired,
  scheduleOrOption: PropTypes.string,
};

export default AvailabilitySchedulePanel;
