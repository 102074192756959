// LogoSection.js
import React from "react";
import { Img, Section } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";

const LogoSection = () => (
  <Tailwind>
    <Section className="bg-[#F2F2F2] flex justify-center pb-5 mt-8">
      <Img
        src="https://email-assets.lokalee.app/email/emailImages/logo.png"
        alt="Logo"
        className="w-full h-auto mx-auto mt-5"
      />
    </Section>
  </Tailwind>
);

export default LogoSection;
