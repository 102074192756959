/* eslint-disable func-names */
/* eslint-disable camelcase */
/**
=========================================================
* LCM Dashboard V2
=========================================================

* Product Page: https://www.lcm.lokalee.app
* Copyright 2023 Lokalee (https://www.lokalee.app)

 =========================================================
*/

import * as Yup from "yup";
import checkout from "../../new-ticket/schemas/form";

const {
  formField: {
    title,
    provider,
    curator,
    overview,
    destinations,
    primaryDestination,
    minTravelersPerBooking,
    maxTravelersPerBooking,
    url,
    images,
    ageBands,
    endDate,
    // availabilitiesAdded,
    defaultCurrencyCode,
    ageBandsObject,
    cutoff_days,
    cutoff_time,
  },
} = checkout;

const validations = [
  // validation for Ticket Info page
  Yup.object().shape({
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),

    [provider.name]: Yup.object().shape({
      email: Yup.string()
        .email("Your provider email address is invalid!")
        .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, "Your email address is invalid!"),
    }),

    tag: Yup.object()
      .test("tag-required", "Tag name is required", function (value) {
        const { tags } = this.parent;
        // Check if tags length is 0 and the tag object is empty
        return !(tags?.length === 0 && !value?.label);
      })
      .shape({
        name: Yup.string(),
      }),

    [overview.name]: Yup.string().required(overview.errorMsg),

    [curator.name]: Yup.object().when("curatorOrProv", {
      is: "curator",
      then: () => Yup.object().required(curator?.errorMsg),
    }),
    [destinations.name]: Yup.array()
      .min(1, destinations?.invalidMsg)
      .required(destinations?.errorMsg),
    [primaryDestination.name]: Yup.mixed().required(primaryDestination.errorMsg),
  }),
  // validation for content page
  Yup.object().shape({
    [minTravelersPerBooking.name]: Yup.number().notOneOf([0], minTravelersPerBooking.errorMsg),
    [maxTravelersPerBooking.name]: Yup.number().notOneOf([0], maxTravelersPerBooking.errorMsg),
  }),

  // validation for media page
  Yup.object().shape({
    [images.name]: Yup.array().min(1, images.errorMsg).required(),
  }),

  // validation for schedules and Price page
  Yup.object().shape({
    [ageBands.name]: Yup.array()
      .of(Yup.string())
      .test(
        "at-least-one-adult-or-traveler",
        "At least one age band (ADULT or TRAVELER) must be selected!",
        function (value) {
          // First, check if "ADULT" or "TRAVELER" is directly in the ageBands array
          const hasAdultOrTraveler = value.includes("ADULT") || value.includes("TRAVELER");

          if (hasAdultOrTraveler) {
            // If "ADULT" or "TRAVELER" is directly in the ageBands array, no further validation is needed
            return true;
          }

          // Otherwise, proceed to check the nested structure in availabilities
          const bookableItems = this.parent.availabilities?.bookableItems || [];
          return bookableItems.some((bookableItem) =>
            bookableItem.seasons?.some((season) =>
              season.pricingRecords?.some((pricingRecord) =>
                pricingRecord.pricingDetails?.some((pricingDetail) =>
                  ["ADULT", "TRAVELER"].includes(pricingDetail.ageBand)
                )
              )
            )
          );
        }
      )
      .required("Age bands are required"),

    [defaultCurrencyCode.name]: Yup.object().required(defaultCurrencyCode.errorMsg),
    // [defaultCurrencyCode.name]: Yup.object().required("Currency is required!"),
    // [optionName.name]: Yup.string().required(optionName.errorMsg),
    [endDate.name]: Yup.string().test({
      name: "greaterThanStartDate",
      exclusive: false,
      message: "End date must be greater than or equal to start date!",
      test(val) {
        const startDat = this.resolve(Yup.ref("startDate"));
        return !startDat || !val || startDat <= val;
      },
    }),
    // [optionName.name]: Yup.string().when("scheduleOrOption", {
    //   is: "option",
    //   then: () => Yup.string().required(optionName.errorMsg),
    //   otherwise: () => Yup.string(),
    // }),

    [ageBandsObject.name]: Yup.array()
      .of(
        Yup.object().shape({
          ageBand: Yup.string().required("Age band is required"),
          startAge: Yup.number().nullable(),
          endAge: Yup.number().nullable(),
        })
      )
      .test("start-end-age-required", "Both min age and max age are required!", (value) =>
        value?.every((obj) => obj.startAge !== undefined && obj.endAge !== undefined)
      ),
    // .test(
    //   "ageBand-match",
    //   "Each ageBand in ageBandsObject must match an ageBand in ageBands",
    //   function (value) {
    //     const { ageBands: ageBandsList } = this.parent;
    //     if (!ageBandsList) {
    //       return true; // Skip validation if ageBands is not defined
    //     }
    //     return value.every((obj) => ageBandsList.includes(obj.ageBand));
    //   }
    // ),

    // to be checked later if it should have a validation or no
    // [availabilitiesAdded.name]: Yup.array()
    //   .min(1, availabilitiesAdded?.invalidMsg)
    //   .required(availabilitiesAdded?.invalidMsg),

    /// // [pricesObject.name]: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       ageBand: Yup.string().required("Age band is required"),
    //       minTravelers: Yup.number().nullable(),
    //       netPrice: Yup.number().nullable(),
    //       maxTravelers: Yup.number().nullable(),
    //       retailPrice: Yup.number().nullable(),
    //     })
    //   )
    //   .test("max-min-required", "All fields are required!", (value) =>
    //     // const { addSchedule } = context.parent;
    //     // if (!addSchedule) return true; // Skip validation if addSchedule is false

    //     value.every(
    //       (obj) =>
    //         (obj.maxTravelers !== undefined || "") &&
    //         (obj.minTravelers !== undefined || "") &&
    //         (obj.netPrice !== undefined || "") &&
    //         (obj.ageBand !== undefined || "") &&
    //         (obj.retailPrice !== undefined || "")
    //     )
    //   )
    //   .test(
    //     "prices-match",
    //     "Each prices in pricesObject must match an price in prices",
    //     function (value) {
    //       const { prices: pricesList } = this.parent;
    //       if (!pricesList) {
    //         return true; // Skip validation if ageBands is not defined
    //       }
    //       return value.every((obj) => pricesList.includes(obj.ageBand));
    //     }
    //   ),
  }),
  // validations for booking and tickets Page
  Yup.object().shape({
    [url.name]: Yup.string().matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
      url.invalidMsg
    ),
    [cutoff_days.name]: Yup.number().positive(cutoff_days.invalidMsg),
    [cutoff_time.name]: Yup.number().positive(cutoff_time.invalidMsg),
  }),
];

export default validations;
