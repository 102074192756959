/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Layout
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MaterialTablePicker from "components/MaterialTablePicker";

// Material UI components
import Grid from "@mui/material/Grid";

// Redux Actions
import setAlert from "store/actions/alertActions";
import {
  getAllVouchers,
  clearErrors,
  deleteVoucher,
  getLocationVoucher,
  deleteSingleVoucher,
} from "store/actions/voucherActions";
import LocationUserPartner from "components/LocationUserPartner";
import { getUserPartnerLocations, setLoading, setLocation } from "store/actions/locationAction";
import { getPdf, getCsv } from "store/actions/exportAction";
import form from "../new-voucher/schemas/form";

const AllVouchers = () => {
  const { location } = form.formField;

  const tableRef = createRef();
  const dispatch = useDispatch();

  const vouchers = useSelector((state) => state.vouchers.vouchers);

  const error = useSelector((state) => state.vouchers.error);
  const user = useSelector((state) => state.users.user);
  const loading = useSelector((state) => state.vouchers.loading);
  const locations = useSelector((state) => state.locations.locations);
  const locationVoucher = useSelector((state) => state.locations.location);
  const added = useSelector((state) => state.vouchers.added);

  const [locationId, setLocationId] = useState("");
  const deleteVoucherFn = (id) => {
    dispatch(deleteVoucher(id));
  };

  const handleChange = (option, val) => {
    if (locationVoucher) dispatch(deleteSingleVoucher());

    setLocationId(val?._id);
    dispatch(setLocation(val?._id));
    dispatch(getLocationVoucher(val?._id));
  };

  useEffect(() => {
    if (locations?.length > 0 && !locationVoucher) {
      setLocationId(locations[0]?._id);
    }
  }, [locations]);

  useEffect(() => {
    dispatch(setLoading());
    if (user.role === "partner") {
      dispatch(getUserPartnerLocations());
    } else if (user.role === "admin") {
      dispatch(setLoading());
      dispatch(getAllVouchers());
    }
  }, [user.role, added]);

  useEffect(() => {
    if (locationId) {
      dispatch(setLoading());
      dispatch(getLocationVoucher(locationVoucher || locationId));
    }
  }, [locationVoucher, locationId]);

  // to add use Effect for set locaton ID
  useEffect(() => {
    if (locationVoucher && locationId !== locationVoucher) {
      setLocationId(locationVoucher);
      dispatch(setLocation(locationVoucher));
      getLocationVoucher(locationVoucher);
    }
  }, [locationVoucher, locationId]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            {user?.role === "partner" && locations.length > 0 && (
              <LocationUserPartner
                defaultLocationId={locationId}
                handleChange={handleChange}
                locations={locations || {}}
                location={location}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {(user.role === "admin" || (user.role === "partner" && locationId)) && (
              <MaterialTablePicker
                screen="VouchersList"
                title="Vouchers"
                key={added}
                searchField="vouchers"
                exportMenuField="vouchers"
                onRowClickURL={`/${user.role}/vouchers`}
                currentUrl="vouchers"
                tableRef={tableRef}
                columns={[
                  "Title",
                  "Recommendation Title",
                  "Type",
                  "Value",
                  "Quantity",
                  "Quantity Remaining",
                  "Start Date ",
                  "End Date ",
                ]}
                data={vouchers}
                isLoading={loading}
                filteringOptions={false}
                addUrl="vouchers/new-voucher"
                actionsName="Voucher"
                editableDelete={deleteVoucherFn}
                deleteRoleAction
                editableRole={user.role === "admin" || user.role === "partner"}
                actionsRole={user.role === "admin" || user.role === "partner"}
                getPdf={(value) => {
                  if (value) {
                    if (user?.role === "partner") {
                      dispatch(
                        getPdf(
                          value,
                          `locations/${locationId}/vouchers/createPdf?limit=${vouchers?.length}&page=1&search=&pdf=true&fields=title,recommendation,type,value,quantityPerDay,quantityRemaining,startDate,endDate`,
                          "Vouchers"
                        )
                      );
                    } else {
                      dispatch(
                        getPdf(
                          value,
                          `stats/pdf?limit=${vouchers?.length}&page=1&search=&fields=title,recommendation,type,value,quantityPerDay,quantityRemaining,startDate,endDate`,
                          "Vouchers"
                        )
                      );
                    }
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    // eslint-disable-next-line no-console
                    console.error("Error: No data for PDF export.");
                  }
                }}
                getCsv={(value) => {
                  if (value) {
                    if (user?.role === "partner") {
                      dispatch(
                        getCsv(
                          `${process.env.REACT_APP_API}/locations/${locationId}/vouchers/csv?limit=${vouchers?.length}&page=1&search=&csv=true&fields=title,recommendation,type,value,quantityPerDay,quantityRemaining,startDate,endDate`,
                          "Vouchers"
                        )
                      );
                    } else {
                      dispatch(
                        getCsv(
                          value,
                          `stats/csv?limit=${vouchers?.length}&page=1&search=&fields=title,recommendation,type,value,quantityPerDay,quantityRemaining,startDate,endDate`,
                          "Vouchers"
                        )
                      );
                    }
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    // eslint-disable-next-line no-console
                    console.error("Error: No data for CSV export.");
                  }
                }}
              />
            )}
          </Grid>
        </Grid>
        <Footer />
      </DashboardLayout>
    </div>
  );
};

export default AllVouchers;
